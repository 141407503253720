<template>
  <section class="invoice-preview-wrapper">
    <div
      v-show="show"
      id="lottie"
    >
      <div class="loading-logo">
        <img
          style="margin-left: -4rem;"
          alt="Logo"
          src="/logo-transparent.png"
        >
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>

    <b-col
      v-if="quote.id"
      class="notprintable"
    >
      <b-row>
        <b-col
          cols="7"
          style="font-weight: 600; font-size: 11.9px; line-height: 12px"
        >
          <quote-info-header :quote="quote" />
          <!-- <h2
              v-if="quote.id"
              class="notprintable"
              style="font-weight: 700 !important; font-size: 28px!important; line-height: 34px;!important"
            >
              {{ quote.opportunity.address + ", " + quote.opportunity.geography }}
            </h2>
            <uil-suitcase class="logo" size="15px" />
            {{ quote.opportunity.account_name }}
            <span style="padding-left: 20px">
              <uil-tape class="logo" size="15px" />
              {{ quote.opportunity.rentable_sqft.toLocaleString('en', {useGrouping:true}) + " sqft" }}
            </span>
            <span style="padding-left: 20px">
              <uil-bill class="logo" size="15px" />
              {{ quote.tfr_name }}
            </span>
            <span style="padding-left: 20px; font-weight: 400; color: #b9b9c3">
              {{ "DC Version " + quote.base_spec_version }}
            </span>
            <p class="pt-1">
              <b-badge pill :class="badgeClass(quote.tfr.tfr_status)">
                {{ quote.tfr.tfr_status }}
              </b-badge>
            </p> -->
        </b-col>
        <b-col
          align-self="end"
          style="text-align: end; padding-bottom: 20px"
        >
          <b-button
            role="button"
            style="margin-right: 10px"
            target="_blank"
            variant="outline-secondary"
            @click="downloadCadFile"
          >
            Download CAD Data
            <feather-icon icon="DownloadIcon" />
          </b-button>
          <!-- <b-button
              :href="`https://clearspace.lightning.force.com/lightning/r/Test_Fit_Request__c/${quote.tfr.sf_tfr_id}/view`"
              role="button"
              style="margin-right: 10px"
              target="_blank"
              variant="outline-secondary"
              :disabled="!quote.tfr.sf_tfr_id"
            >
              Open TFR
              <feather-icon icon="ExternalLinkIcon" />
            </b-button> -->
          <b-button
            v-if="quoteHasChanged || hasComment"
            style="margin-right: 10px"
            variant="outline-danger"
            @click="showModal"
          >
            Discard Changes
          </b-button>
          <b-button
            v-else
            style="margin-right: 10px"
            variant="outline-secondary"
            @click="exitEditMode"
          >
            Open TFR
            <feather-icon icon="ExternalLinkIcon" />
          </b-button>
          <b-button
            variant="primary"
            :disabled="
              (quote.tfr.tfr_status == 'Quote Completed' ||
                quote.opportunity.ccdc_signed == true || quote.tfr.tfr_status=='Archived')|| savingQuote
            "
            @click="updateQuote"
          >
            Save Quote
          </b-button>
        </b-col>
      </b-row>
    </b-col>
    <b-row
      v-if="quote.id"
      class="invoice-preview edit-page"
    >
      <!-- Left Col: Card -->
      <!-- <b-col cols="12" md="4" xl="3" class="invoice-actions">
            <comment-section :comments='comments' :quoteId='quoteId' />
        </b-col> -->
      <b-col
        cols="12"
        md="12"
        xl="12"
      >
        <b-tabs
          v-model="tabIndex"
          pills
          vertical
          nav-wrapper-class="col-3"
          content-class="col-9"
        >
          <template v-if="!isBusy">
            <div
              v-if="
                quote.tfr.tfr_status == 'Quote Completed' ||
                  quote.opportunity.ccdc_signed == true
              "
              class="archived-warning"
            >
              <span>Estimate is complete or marked as contract signed and can no longer be edited.</span>
            </div>
            <div
                v-if="(Number(quote.contingency_fee_percentage)<5 && quote.cm_fee_enabled===false)||(quote.cm_fee_enabled===true && Number(quote.cm_fee_percentage)< 3 )"
                class="alert alert-danger p-1"
            >
              <span
              >Alert: Contingency is below 5% or the management fee is too low. Please review for accuracy.</span
              >
            </div>
            <div
              v-if="
                quote.tfr.tfr_status == 'Archived'
              "
              class="archived-warning-archived"
            >
              <span>Estimate is archived and cannot be edited.</span>
            </div>
          </template>
          <b-tab active>
            <template #title>
              <b-col
                align-self="start"
                cols="11"
              >
                <uil-bill
                  class="logo"
                  size="15px"
                />
                Summary
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <!-- Col: Right (Invoice Container) -->
            <template v-if="!isBusy">
              <div
                v-if="showFFEAlert == true || showAVITAlert == true"
                class="alert-warning"
              >
                <span>
                  <UilExclamationTriangle
                    fill="#FF9F43"
                    size="15px"
                  />
                  Additions are still being included in a disabled budget group.</span>
              </div>
            </template>
            <b-card
              class="invoice-preview-card"
              no-body
            >
              <div
                style="background-color: rgba(50, 61, 76, 1)"
                class="invoice_header text-white invoice-padding m-0 p-0"
              >

                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">
                  <div
                    class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                  >
                    <!-- Header: Left Content -->
                    <div>
                      <div class="logo-wrapper">
                        <logo />
                      </div>
                      <p class="card-text mb-25">
                        Clearspace Offices Inc.
                      </p>
                      <p class="card-text mb-25 base-paragraph">
                        901-20 Victoria Street
                        <!-- {{
                            quote.geography != "Montréal"
                              ? "901-20 Victoria Street"
                              : montrealAddress
                          }} -->
                      </p>
                      <p class="card-text mb-0 base-paragraph">
                        Toronto, ON, Canada M5C 2N8
                        <!-- {{
                            quote.geography != "Montréal"
                              ? "Toronto, ON, Canada M5C 2N8"
                              : "Montréal, QC, Canada H3B 2N2"
                          }} -->
                      </p>
                      <div
                        v-if="quote.geography == 'Montréal'"
                        class="mt-md-0 mt-2"
                        v-html="$t('changeOrder.changeOrderLicenseCF', 'en')"
                      />
                    </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <h4
                        class="invoice-numbercs mb-2 text-white"
                        style="text-align: end"
                      >
                        Summary #{{ quote.tfr.tfr }}
                      </h4>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title header-label">
                          Issue Date:
                        </p>
                        <p class="invoice-date">
                          <b-input-group class="mb-1">
                            <b-form-input
                              id="example-input"
                              v-model="quote.issue_date"
                              autocomplete="off"
                              placeholder="YYYY-MM-DD"
                              show-decade-nav
                              type="text"
                            />
                            <b-input-group-append>
                              <b-form-datepicker
                                v-model="quote.issue_date"
                                aria-controls="example-input"
                                button-only
                                button-variant="outline-secondary"
                                locale="en-US"
                                right
                                show-decade-nav
                                size="sm"
                              />
                            </b-input-group-append>
                          </b-input-group>
                        </p>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title header-label">
                          Expiry Date:
                        </p>
                        <div class=" w-100 text-right">

                          <p class="invoice-date ">
                            {{ new Date(quote.expiration_date).toLocaleDateString('en-CA') }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>

                <!-- Spacer
          <hr class="invoice-spacing">
          -->

              </div>
              <!-- Invoice Client & Payment Details -->
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col
                    class="p-0"
                    cols="9"
                  >
                    <h6 class="header-label">
                      Address
                    </h6>
                    <h2
                      class="card-text mb-25"
                      style="
                        font-weight: 700 !important;
                        font-size: 21px !important;
                        line-height: 26px !important;
                        color: #636363 !important;
                      "
                    >
                      {{ quote.address }}
                    </h2>
                    <h2
                      class="card-text mb-25"
                      style="
                        font-weight: 700 !important;
                        font-size: 21px !important;
                        line-height: 26px !important;
                        color: #636363 !important;
                      "
                    >
                      {{ quote.geography }}
                      <!-- {{quote.project.city + ', ' + quote.project.state + ', Canada ' + quote.project.postcode}} -->
                    </h2>
                    <h6 class="mt-2 header-label">
                      Size
                    </h6>
                    <p class="card-text mb-2">
                      {{
                        Number(quote.sqft)
                          .toLocaleString('en', {
                            minimumFractionDigits: 0,
                          }) + ' sqft'
                      }}
                    </p>
                  </b-col>
                  <!-- <b-col
                        class="p-0"
                        cols="5"
                    >
                      <h6 class="mb-1 header-label">
                        Bill To
                      </h6>
                      <p class="card-text mb-25">
                        {{ quote.opportunity.account_name }}
                      </p>
                      <p class="card-text mb-25">
                        {{ quote.opportunity.contact_name }}
                      </p>
                      <p class="card-text mb-25">
                        {{ quote.opportunity.contact_phone }}
                      </p>
                      <p class="card-text mb-0">
                        {{ quote.opportunity.contact_email }}
                      </p>
                    </b-col> -->

                  <!-- Col: Payment Details -->
                  <!-- <b-col class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end" cols="3">
                    <div class="width-100p">
                      <h6 class="mb-2 header-label">Quote Summary</h6>
                      <p class="header-label-grey">$ per sqft</p>
                      <table class="width-100p">
                        <tbody>
                          <tr>
                            <td class="pr-1 pb-1" colspan="4">Turnkey Suite</td>
                            <td class="header-subtotal pr-1 pb-1" colspan="1">
                              {{
                                "$" +
                                Number(
                                  quote.construction_cost_per_sqft +
                                    quote.site_conditions_construction_price_per_sqft +
                                    quote.options_construction_price_per_sqft +
                                    quote.construction_buffer * 1 +
                                    Number(
                                      (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                                        quote.options_ffe_price_per_sqft +
                                        Number(
                                          (!quote.avit_toggle
                                            ? 0
                                            : quote.avit_price_per_sqft) +
                                            quote.options_avit_price_per_sqft
                                        ) +
                                        Number(
                                          quote.construction_pm_buffer * 1 +
                                            quote.design_ame_buffer * 1 +
                                            Number(quote.engineering_fee_price) * 1
                                        ) +
                                        Number(quote.permit_fees / quote.sqft)
                                    )
                                ).toLocaleString("en", { minimumFractionDigits: 2 })
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 pb-1" colspan="4">Additions</td>
                            <td class="header-subtotal pr-1 pb-1" colspan="1">
                              {{
                                [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_price_per_sqft))
                                    .toLocaleString('en', { minimumFractionDigits: 2 })
                              }}
                            </td>
                          </tr>
                          <tr style="border-bottom: 1px solid #d8d6de">
                            <td class="pr-1 pb-1" colspan="4">Cash Allowances</td>
                            <td class="header-subtotal pr-1 pb-1" colspan="1">
                              {{
                                Number(cashAllowanceSubtotal / quote.sqft).toLocaleString(
                                  "en-CA",
                                  {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  }
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 pb-2 pt-2 project-sqft" colspan="4">
                              Project $/sqft
                            </td>
                            <td class="project-sqft-total pr-1 pb-1 pt-2" colspan="1">
                              {{
                                "$" +
                                Number(
                                  quote.construction_cost_per_sqft +
                                    quote.site_conditions_construction_price_per_sqft +
                                    quote.options_construction_price_per_sqft +
                                    quote.construction_buffer * 1 +
                                    Number(
                                      (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                                        quote.options_ffe_price_per_sqft +
                                        Number(
                                          (!quote.avit_toggle
                                            ? 0
                                            : quote.avit_price_per_sqft) +
                                            quote.options_avit_price_per_sqft
                                        ) +
                                        Number(
                                          quote.construction_pm_buffer * 1 +
                                            quote.design_ame_buffer * 1 +
                                            Number(quote.engineering_fee_price) * 1
                                        ) +
                                        Number(quote.permit_fees / quote.sqft)
                                    ) +
                                    Number(quote.site_conditions_price_per_sqft * 1) +
                                    Number(quote.options_price_per_sqft * 1)
                                ).toLocaleString("en", { minimumFractionDigits: 2 })
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="project-sqft pr-1 pb-1" colspan="4">
                              Project Total
                            </td>
                            <td class="header-subtotal pr-1 pb-1" colspan="1">
                              {{
                                "$" +
                                Number(
                                  Number(
                                    quote.construction_buffer * 1 * quote.sqft +
                                      siteConditionsConstructionSubtotal +
                                      optionsConstructionSubtotal +
                                      quote.construction_calc
                                  ) +
                                    Number(
                                      (!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                                        optionsFfeSubtotal
                                    ) +
                                    Number(
                                      (!quote.avit_toggle ? 0 : quote.avit_calc) +
                                        optionsAvItSubtotal
                                    ) +
                                    Number(
                                      (quote.construction_pm_buffer * 1 +
                                        quote.design_ame_buffer * 1 +
                                        Number(quote.engineering_fee_price) * 1) *
                                        quote.sqft
                                    ) +
                                    Number(quote.permit_fees) +
                                    Number(quote.options_total_price) +
                                    Number(quote.site_conditions_total_price)
                                ).toLocaleString("en", { minimumFractionDigits: 2 })
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col> -->
                </b-row>
              </b-card-body>
              <caption class="caption-table">
                Estimate Summary
              </caption>
              <edit-summary-section
                :quote="quote"
                :check-hidden-construction="checkHiddenConstruction"
                :check-hidden-f-f-e="checkHiddenFFE"
                :check-hidden-a-v-i-t="checkHiddenAVIT"
                :options-construction-subtotal="optionsConstructionSubtotal"
                :options-ffe-subtotal="optionsFfeSubtotal"
              />
              <!-- Invoice Description: Table -->

              <!-- Spacer -->
              <!-- <div
                v-if="
                  Number(Math.abs(quote.site_conditions_price_per_sqft)) <= 0 &&
                  Number(Math.abs(quote.options_price_per_sqft)) <= 0
                "
              >
                <hr class="invoice-spacing" style="border: 2px solid #82868b" />
                <b-table-simple
                  fixed
                  style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
                >
                  <b-tfoot>
                    <b-tr>
                      <b-td
                        class="row-label project-total-label"
                        colspan="4"
                        style="text-align: left; border-top: none"
                      >
                        Project Total
                      </b-td>
                      <b-td
                        class="row-label project-total-number"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{
                          Number(
                            quote.construction_cost_per_sqft +
                              quote.site_conditions_construction_price_per_sqft +
                              quote.options_construction_price_per_sqft +
                              quote.construction_buffer * 1 +
                              Number(
                                (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                                  quote.options_ffe_price_per_sqft +
                                  Number(
                                    (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                                      quote.options_avit_price_per_sqft
                                  ) +
                                  Number(
                                    quote.construction_pm_buffer * 1 +
                                      quote.design_ame_buffer * 1 +
                                      Number(quote.engineering_fee_price) * 1
                                  ) +
                                  Number(quote.permit_fees / quote.sqft)
                              ) +
                              Number(quote.site_conditions_price_per_sqft * 1) +
                              Number(quote.options_price_per_sqft * 1)
                          ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </b-td>
                      <b-td
                        class="row-label project-total-number cost-line"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{

                          Number(
                            Number(
                              quote.construction_cost_per_sqft +
                                quote.options_construction_cost_per_sqft +
                                quote.site_conditions_construction_cost_per_sqft
                            ) +
                              Number(
                                (!quote.ffe_toggle
                                  ? 0
                                  : quote.ffe_cost_per_sqft +
                                    quote.ffe_installation_price_per_sqft) +
                                  quote.options_ffe_cost_per_sqft
                              ) +
                              Number(
                                (!quote.avit_toggle
                                  ? 0
                                  : quote.avit_cost_per_sqft +
                                    quote.avit_installation_price_per_sqft) +
                                  quote.options_avit_cost_per_sqft
                              ) +
                              Number(Number(quote.engineering_fee_cost) * 1) +
                              Number(quote.permit_fees / quote.sqft) +
                              Number(quote.site_conditions_cost_per_sqft) +
                              Number(quote.options_cost_per_sqft)
                          ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </b-td>
                      <b-td
                        class="row-label project-total-number"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{
                          Number(
                            Number(
                              quote.construction_buffer * 1 * quote.sqft +
                                siteConditionsConstructionSubtotal +
                                optionsConstructionSubtotal +
                                quote.construction_calc
                            ) +
                              Number(
                                (!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                                  optionsFfeSubtotal
                              ) +
                              Number(
                                (!quote.avit_toggle ? 0 : quote.avit_calc) +
                                  optionsAvItSubtotal
                              ) +
                              Number(
                                (quote.construction_pm_buffer * 1 +
                                  quote.design_ame_buffer * 1 +
                                  Number(quote.engineering_fee_price) * 1) *
                                  quote.sqft
                              ) +
                              Number(quote.permit_fees) +
                              Number(quote.options_total_price) +
                              Number(quote.site_conditions_total_price)
                          ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </b-td>
                      <b-td
                        class="project-total-number cost-line"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{
                          Number(
                            quote.construction_buffer * 1 +
                              (Number(
                                quote.site_conditions_construction_price_per_sqft +
                                  quote.options_construction_price_per_sqft
                              ) -
                                Number(
                                  quote.options_construction_cost_per_sqft +
                                    quote.site_conditions_construction_cost_per_sqft
                                )) +
                              Number(!quote.ffe_toggle ? 0 : quote.ffe_buffer) +
                              Number(quote.options_ffe_price_per_sqft) -
                              Number(quote.options_ffe_cost_per_sqft) +
                              Number(!quote.avit_toggle ? 0 : quote.avit_buffer) +
                              Number(quote.options_avit_price_per_sqft) -
                              Number(quote.options_avit_cost_per_sqft) +
                              Number(
                                quote.options_price_per_sqft - quote.options_cost_per_sqft
                              ) +
                              Number(
                                quote.site_conditions_price_per_sqft -
                                  quote.site_conditions_cost_per_sqft
                              ) +
                              quote.design_ame_buffer +
                              quote.construction_pm_buffer +
                              Number(
                                Number(Number(quote.engineering_fee_price)) * 1 -
                                  Number(quote.engineering_fee_cost) * 1
                              )
                          ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </b-td>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>
              </div> -->

              <!-- Note -->
              <!--              <b-card-body class="invoice-padding pt-0" style="visibility: hidden">-->
              <!--                <span class="disclaimer">This pricing does not contemplate liquidated damages. If liquidated damages are required, a premium will apply.<br>-->
              <!--                  Quote does NOT include <span v-if="quote.opportunity.geography == 'Toronto'">HST.</span><span-->
              <!--                      v-else-->
              <!--                  >include GST + QST.</span> <br>-->
              <!--                  Quote does NOT include any landlord required reviews and backcharges (incl. after hour fees, commissioning, security, engineer reviews, etc.).<br>-->
              <!--                  Changes to the scope of work listed above will be priced separately and agreed to in writing before additional work is performed. </span>-->
              <!--              </b-card-body>-->
            </b-card>
            <!-- <b-card
              v-if="
                Number(Math.abs(quote.site_conditions_price_per_sqft)) > 0 ||
                Number(Math.abs(quote.options_price_per_sqft)) > 0
              "
              class="invoice-preview-card pagebreak"
              no-body
            > -->
            <!-- Header -->
            <!-- <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              > -->
            <!-- Header: Left Content -->
            <!-- <div /> -->

            <!-- Header: Right Content -->
            <!-- <div class="mt-md-0 mt-2">
              <h4 class="invoice-numbercs mb-2">Quote #{{ quote.tfr_name }}</h4>
            </div>
          </div> -->

            <!-- Invoice Description: Table -->
            <!-- <div v-if="Number(Math.abs(quote.site_conditions_price_per_sqft)) > 0">
              <h4 style="padding-right: 28px; padding-left: 28px; font-weight: 500">
                Site Conditions
                <span
                  class="pl-1"
                  style="color: #7ec242; font-size: 14px; cursor: pointer"
                  @click="(tabIndex = 4), scrollToTop()"
                >
                  <uil-edit-alt fill="#7EC242" size="15px" /> Edit Section
                </span>
              </h4>

              <br />
              <b-table-simple
                fixed
                style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
              >
                <b-thead>
                  <b-tr>
                    <b-th
                      class="table-background-color"
                      colspan="4"
                      style="text-align: left"
                    >
                      ITEM
                    </b-th>
                    <b-th
                      class="table-background-color"
                      colspan="2"
                      style="text-align: left"
                    >
                      PRICE/SQFT
                    </b-th>
                    <b-th
                      class="table-background-color cost-header"
                      colspan="2"
                      style="text-align: left"
                    >
                      COGS / SQFT
                    </b-th>
                    <b-th
                      class="table-background-color"
                      colspan="2"
                      style="text-align: left"
                    >
                      SUBTOTAL
                    </b-th>
                    <b-th
                      class="table-background-color cost-header"
                      colspan="2"
                      style="text-align: left"
                    >
                      PROFIT/SQFT
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(siteCondition, index) in siteConditions"
                    :key="index"
                    class="border-group-details"
                  >
                    <template v-if="!siteCondition.is_base_spec">
                      <b-td class="row-label" colspan="4">
                        <div class="row-label">
                          {{ siteCondition.name }}
                        </div>
                        <div
                          v-if="siteCondition.details"
                          class="op-sc-details child-description"
                          style="white-space: pre-line"
                        >
                          {{ siteCondition.details }}
                        </div>
                      </b-td>
                      <b-td
                        :class="
                          [siteCondition.price_per_sqft < 0 ? 'credit ' : ''] +
                          'row-label'
                        "
                        colspan="2"
                        >{{
                            [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((siteCondition.price_per_sqft)))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        :class="
                          [siteCondition.price_per_sqft < 0 ? 'credit ' : ''] +
                          'row-label cost-line'
                        "
                        colspan="2"
                        >{{
                            [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((siteCondition.cost_per_sqft)))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        :class="[siteCondition.price_per_sqft < 0 ? 'credit ' : '']"
                        colspan="2"
                        >{{
                            [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(siteCondition.total_price))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        :class="
                          [siteCondition.price_per_sqft < 0 ? 'credit ' : ''] +
                          'row-label cost-line'
                        "
                        colspan="2"
                        >{{
                            [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((siteCondition.price_per_sqft - siteCondition.cost_per_sqft)))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                    </template>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr class="border-footer">
                    <b-td class="row-label" colspan="4" style="text-align: left">
                      Site Conditions Total
                    </b-td>
                    <b-td class="row-label" colspan="2"
                      >{{
                          [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_price_per_sqft))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label cost-line" colspan="2">
                      {{
                          [(quote.site_conditions_cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_cost_per_sqft))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label" colspan="2"
                      >{{
                          [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_total_price))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="cost-line" colspan="2"
                      >{{
                          [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_price_per_sqft - quote.site_conditions_cost_per_sqft))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </div> -->
            <!-- <div v-if="Number(Math.abs(quote.options_price_per_sqft)) > 0">
              <h4 style="padding-right: 28px; padding-left: 28px; padding-top: 50px">
                Additions
                <span
                  class="pl-1"
                  style="color: #7ec242; font-size: 14px; cursor: pointer"
                  @click="(tabIndex = 4), scrollToTop()"
                >
                  <uil-edit-alt fill="#7EC242" size="15px" /> Edit Section
                </span>
              </h4>
              <br />
              <b-table-simple
                fixed
                style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
              >
                <b-thead>
                  <b-tr>
                    <b-th
                      class="table-background-color"
                      colspan="4"
                      style="text-align: left"
                    >
                      ITEM
                    </b-th>
                    <b-th
                      class="table-background-color"
                      colspan="2"
                      style="text-align: left"
                    >
                      PRICE/SQFT
                    </b-th>
                    <b-th
                      class="table-background-color cost-header"
                      colspan="2"
                      style="text-align: left"
                    >
                      COGS / SQFT
                    </b-th>
                    <b-th
                      class="table-background-color"
                      colspan="2"
                      style="text-align: left"
                    >
                      SUBTOTAL
                    </b-th>
                    <b-th
                      class="table-background-color cost-header"
                      colspan="2"
                      style="text-align: left"
                    >
                      PROFIT/SQFT
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(option, index) in options"
                    :key="'opt' + index"
                    class="border-group-details"
                  >
                    <template v-if="!option.is_base_spec">
                      <b-td colspan="4">
                        <div class="row-label">
                          {{ option.name }}
                        </div>
                        <div
                          v-if="option.details"
                          class="op-sc-details child-description"
                          style="white-space: pre-line"
                        >
                          {{ option.details }}
                        </div>
                      </b-td>
                      <b-td
                        :class="
                          [option.price_per_sqft < 0 ? 'credit ' : ''] + 'row-label'
                        "
                        colspan="2"
                        >{{
                            [(option.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((option.price_per_sqft)))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        :class="
                          [option.price_per_sqft < 0 ? 'credit ' : ''] +
                          'row-label cost-line'
                        "
                        colspan="2"
                        >{{
                            [(option.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((option.cost_per_sqft)))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        :class="[option.price_per_sqft < 0 ? 'credit ' : '']"
                        colspan="2"
                        >{{
                            [(option.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(option.total_price))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        :class="
                          [option.price_per_sqft < 0 ? 'credit ' : ''] +
                          'row-label cost-line'
                        "
                        colspan="2"
                      >
                        {{
                          option.price_per_sqft - option.cost_per_sqft < 0 ? "-" : ""
                        }}
                        {{
                            [(option.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((option.price_per_sqft - option.cost_per_sqft)))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                    </template>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr class="border-footer">
                    <b-td class="row-label" colspan="4" style="text-align: left">
                      Additions Total
                    </b-td>
                    <b-td class="row-label" colspan="2"
                      >{{
                          [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_price_per_sqft))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label cost-line" colspan="2"
                      >{{
                          [(quote.options_cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_cost_per_sqft))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                      }}
                    </b-td>

                    <b-td colspan="2"
                      >{{
                          [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_total_price))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label cost-line" colspan="2">
                      {{
                        quote.options_price_per_sqft - quote.options_cost_per_sqft < 0
                          ? "-"
                          : ""
                      }}
                      {{
                          [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_price_per_sqft - quote.options_cost_per_sqft))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </div> -->
            <!-- Spacer -->
            <!-- <hr class="invoice-spacing" style="border: 2px solid #82868b" />
            <b-table-simple
              fixed
              style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
            >
              <b-tfoot>
                <b-tr>
                  <b-td
                    class="row-label project-total-label"
                    colspan="4"
                    style="text-align: left; border-top: none"
                  >
                    Project Total
                  </b-td>
                  <b-td
                    class="row-label project-total-number"
                    colspan="2"
                    style="border-top: none"
                  >
                    ${{
                      Number(
                        quote.construction_cost_per_sqft +
                          quote.site_conditions_construction_price_per_sqft +
                          quote.options_construction_price_per_sqft +
                          quote.construction_buffer * 1 +
                          Number(
                            (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                              quote.options_ffe_price_per_sqft +
                              Number(
                                (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                                  quote.options_avit_price_per_sqft
                              ) +
                              Number(
                                quote.construction_pm_buffer * 1 +
                                  quote.design_ame_buffer * 1 +
                                  Number(quote.engineering_fee_price) * 1
                              ) +
                              Number(quote.permit_fees / quote.sqft)
                          ) +
                          Number(quote.site_conditions_price_per_sqft * 1) +
                          Number(quote.options_price_per_sqft * 1)
                      ).toLocaleString("en", { minimumFractionDigits: 2 })
                    }}
                  </b-td>
                  <b-td
                    class="row-label project-total-number cost-line"
                    colspan="2"
                    style="border-top: none"
                  >
                    {{
                      "$" +
                      Number(
                        Number(
                          quote.construction_cost_per_sqft +
                            quote.options_construction_cost_per_sqft +
                            quote.site_conditions_construction_cost_per_sqft
                        ) +
                          Number(
                            (!quote.ffe_toggle
                              ? 0
                              : quote.ffe_cost_per_sqft +
                                quote.ffe_installation_price_per_sqft) +
                              quote.options_ffe_cost_per_sqft
                          ) +
                          Number(
                            (!quote.avit_toggle
                              ? 0
                              : quote.avit_cost_per_sqft +
                                quote.avit_installation_price_per_sqft) +
                              quote.options_avit_cost_per_sqft
                          ) +
                          Number(Number(quote.engineering_fee_cost) * 1) +
                          Number(quote.permit_fees / quote.sqft) +
                          Number(quote.site_conditions_cost_per_sqft) +
                          Number(quote.options_cost_per_sqft)
                      ).toLocaleString("en", { minimumFractionDigits: 2 })
                    }}
                  </b-td>
                  <b-td
                    class="row-label project-total-number"
                    colspan="2"
                    style="border-top: none"
                  >
                    {{
                      "$" +
                      Number(
                        Number(
                          quote.construction_buffer * 1 * quote.sqft +
                            siteConditionsConstructionSubtotal +
                            optionsConstructionSubtotal +
                            quote.construction_calc
                        ) +
                          Number(
                            (!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                              optionsFfeSubtotal
                          ) +
                          Number(
                            (!quote.avit_toggle ? 0 : quote.avit_calc) +
                              optionsAvItSubtotal
                          ) +
                          Number(
                            (quote.construction_pm_buffer * 1 +
                              quote.design_ame_buffer * 1 +
                              Number(quote.engineering_fee_price) * 1) *
                              quote.sqft
                          ) +
                          Number(quote.permit_fees) +
                          Number(quote.options_total_price) +
                          Number(quote.site_conditions_total_price)
                      ).toLocaleString("en", { minimumFractionDigits: 2 })
                    }}
                  </b-td>
                  <b-td
                    class="project-total-number cost-line"
                    colspan="2"
                    style="border-top: none"
                  >
                    {{
                      "$" +
                      Number(
                        quote.construction_buffer * 1 +
                          (Number(
                            quote.site_conditions_construction_price_per_sqft +
                              quote.options_construction_price_per_sqft
                          ) -
                            Number(
                              quote.options_construction_cost_per_sqft +
                                quote.site_conditions_construction_cost_per_sqft
                            )) +
                          Number(!quote.ffe_toggle ? 0 : quote.ffe_buffer) +
                          Number(quote.options_ffe_price_per_sqft) -
                          Number(quote.options_ffe_cost_per_sqft) +
                          Number(!quote.avit_toggle ? 0 : quote.avit_buffer) +
                          Number(quote.options_avit_price_per_sqft) -
                          Number(quote.options_avit_cost_per_sqft) +
                          Number(
                            quote.options_price_per_sqft - quote.options_cost_per_sqft
                          ) +
                          Number(
                            quote.site_conditions_price_per_sqft -
                              quote.site_conditions_cost_per_sqft
                          ) +
                          quote.design_ame_buffer +
                          quote.construction_pm_buffer +
                          Number(
                            Number(Number(quote.engineering_fee_price)) * 1 -
                              Number(quote.engineering_fee_cost) * 1
                          )
                      ).toLocaleString("en", { minimumFractionDigits: 2 })
                    }}
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple> -->
            <!-- </b-card-body> -->

            <!-- Note -->
            <!-- Note -->
            <!-- <b-card-body class="invoice-padding pt-0">
                  <span class="disclaimer">This pricing does not contemplate liquidated damages. If liquidated damages are required, a premium will apply.<br>
                      Quote does NOT include <span v-if="quote.opportunity.geography == 'Toronto'">HST.</span><span v-else>include GST + QST.</span> <br>
                      Quote does NOT include any landlord required reviews and backcharges (incl. after hour fees, commissioning, security, engineer reviews, etc.).<br>
                      Changes to the scope of work listed above will be priced separately and agreed to in writing before additional work is performed. </span>
              </b-card-body> -->
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col
                align-self="start"
                cols="11"
              >
                <uil-constructor
                  class="logo"
                  size="15px"
                />
                Construction
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              >
                <!-- {{
                  Number(
                    quote.construction_cost_per_sqft +
                      quote.construction_buffer * 1 +
                      quote.site_conditions_construction_price_per_sqft +
                      quote.options_construction_price_per_sqft
                  ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                }} -->
              </b-col>
            </template>
            <construction-breakdowns
              :quote="quote"
              :is-busy="isBusy"
              :construction-specs="constructionSpecs"
              :options="options"
              :cm_fee_percentage="quote.cm_fee_percentage"
              :cm_fee_enabled="quote.cm_fee_enabled"
              @base_addition="childOptions"
              @spec_swap="childOptions"
              @delete_cstrn_spec="resetCstrnSpec"
              @update_contingency_fee_percentage="setContingencyFee"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <b-col
                align-self="start"
                cols="11"
              >
                <uil-chair
                  class="logo"
                  size="15px"
                />
                FF&E
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              >
                <!-- {{
                  Number(
                    (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                      quote.options_ffe_price_per_sqft
                  ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                }} -->
              </b-col>
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <!-- <b-row align-h="end">
                  <b-col cols="3" class="my-1" align-self="end" style="text-align: end">
                    <div
                      class="d-flex align-items-center justify-content-end"
                      align-self="end"
                      style="text-align: end"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-append is-text class="reset-append-border">
                          <feather-icon icon="SearchIcon" />
                        </b-input-group-append>
                        <b-form-input
                          id="ffefilter-input"
                          v-model="ffeFilter"
                          type="search"
                          class="d-inline-block"
                          placeholder="Search"
                        />
                      </b-input-group>
                    </div>
                  </b-col>
                </b-row> -->

                <b-row>
                  <b-col cols="12">
                    <edit-ffe-table
                      :quote="quote"
                      @swappSpec="swappSpec"
                      @resetSpec="resetSpec"
                    />
                    <!-- <b-table
                          :busy="isBusy"
                          :fields="FFEbreakdownFields"
                          :items="ffeSpecs"
                          responsive="sm"
                          id="ffeBreakdownTable"
                          :per-page="ffePerPage"
                          :current-page="ffeCurrentPage"
                          :filter="ffeFilter"
                          @filtered="onFilteredFFE"
                          show-empty
                          fixed
                      > -->
                    <!-- <b-table
                      :busy="isBusy"
                      :fields="FFEbreakdownFields"
                      :items="ffeSpecs"
                      responsive="sm"
                      id="ffeBreakdownTable"
                      :filter="ffeFilter"
                      @filtered="onFilteredFFE"
                      show-empty
                      fixed
                    >
                      <template #cell(unit_price)="data">
                        {{
                          Number(data.item.unit_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </template>
                      <template #cell(supply_price)="data">
                        {{
                          Number(data.item.supply_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </template>
                      <template #cell(unit_delivery_install)="data">
                        {{
                          Number(data.item.unit_delivery_install).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </template>
                      <template #cell(qty)="data">
                        {{ Number(data.item.qty).toLocaleString("en") }}
                      </template>
                      <template #cell(total_price)="data">
                        {{
                          Number(data.item.total_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </template>
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle" />
                          <strong> Loading...</strong>
                        </div>
                      </template>
                      <template #emptyfiltered>
                        <b-col cols="12" class="text-center">
                          <b-col cols="12" m-10>
                            <feather-icon
                              icon="SearchIcon"
                              size="4x"
                              style="color: #b9b9c3"
                            />
                          </b-col>
                          <b-col cols="12" class="mt-2">
                            <h4 style="color: #b9b9c3">No Results Found</h4>
                          </b-col>
                          <b-col
                            cols="12"
                            class="mt-1"
                            style="color: #7ec242; cursor: pointer"
                            @click="ffeFilter = null"
                          >
                            Clear search
                          </b-col>
                        </b-col>
                      </template>
                    </b-table> -->
                    <!-- <b-row style="float:right;" v-if="!isBusy">
                        <b-col cols="2">
                          <b-pagination
                              v-model="ffeCurrentPage"
                              :per-page="ffePerPage"
                              :total-rows="ffeRows"
                              aria-controls="ffeBreakdownTable"
                          />
                        </b-col>
                      </b-row> -->
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col
                align-self="start"
                cols="11"
              >
                <uil-wifi
                  class="logo"
                  size="15px"
                />
                AV / IT
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              >
                <!-- {{
                  Number(
                    (!(quote.av_toggle || quote.it_toggle)  ? 0 : (avitGlobalSubTotal()/ quote.sqft))

                  ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                }} -->
              </b-col>
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <edit-avit-table
                  :quote="quote"
                  @swappSpec="swappSpec"
                  @resetSpec="resetSpec"
                />
                <!-- <b-row align-h="end">
                  <b-col cols="3" class="my-1" align-self="end" style="text-align: end">
                    <div
                      class="d-flex align-items-center justify-content-end"
                      align-self="end"
                      style="text-align: end"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-append is-text class="reset-append-border">
                          <feather-icon icon="SearchIcon" />
                        </b-input-group-append>
                        <b-form-input
                          id="avitfilter-input"
                          v-model="avitFilter"
                          type="search"
                          class="d-inline-block"
                          placeholder="Search"
                        />
                      </b-input-group>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <b-table
                      :busy="isBusy"
                      :fields="AVITbreakdownFields"
                      :items="avitSpecs"
                      responsive="sm"
                      id="avitBreakdownTable"
                      :per-page="avitPerPage"
                      :current-page="avitCurrentPage"
                      :filter="avitFilter"
                      @filtered="onFilteredAVIT"
                      show-empty
                      fixed
                    >
                      <template #cell(unit_price)="data">
                        {{
                          Number(data.item.unit_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </template>
                      <template #cell(unit_delivery_install)="data">
                        {{
                          Number(data.item.unit_delivery_install).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </template>
                      <template #cell(supply_price)="data">
                        {{
                          Number(data.item.supply_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </template>
                      <template #cell(qty)="data">
                        {{ Number(data.item.qty).toLocaleString("en") }}
                      </template>
                      <template #cell(total_price)="data">
                        {{
                          Number(data.item.total_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                        }}
                      </template>
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle" />
                          <strong> Loading...</strong>
                        </div>
                      </template>
                      <template #emptyfiltered>
                        <b-col cols="12" class="text-center">
                          <b-col cols="12" m-10>
                            <feather-icon
                              icon="SearchIcon"
                              size="4x"
                              style="color: #b9b9c3"
                            />
                          </b-col>
                          <b-col cols="12" class="mt-2">
                            <h4 style="color: #b9b9c3">No Results Found</h4>
                          </b-col>
                          <b-col
                            cols="12"
                            class="mt-1"
                            style="color: #7ec242; cursor: pointer"
                            @click="avitFilter = null"
                          >
                            Clear search
                          </b-col>
                        </b-col>
                      </template>
                    </b-table>
                    <b-row style="float: right" v-if="!isBusy">
                      <b-col cols="2">
                        <b-pagination
                          v-model="avitCurrentPage"
                          :per-page="avitPerPage"
                          :total-rows="avitRows"
                          aria-controls="avitBreakdownTable"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row> -->
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col
                align-self="start"
                class="d-flex"
                cols="11"
              >
                <uil-plus
                  class="logo"
                  size="15px"
                />
                <div style="width: 80%">
                  Additions
                </div>
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              >
                <!-- {{
                 Number(quote.options_price_per_sqft)
                    .toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                }} -->
              </b-col>
            </template>
            <template v-if="!isBusy">
              <div
                v-if="showFFEAlert == true || showAVITAlert == true"
                class="alert-warning"
              >
                <span>
                  <UilExclamationTriangle
                    fill="#FF9F43"
                    size="15px"
                  />
                  Additions are still being included in a disabled budget group.</span>
              </div>
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <b-card-title>
                  <b-row>
                    <b-col cols="10">
                      <h2>Additions</h2>
                      <p style="color: #6e6b7b; font-size: 12px">
                        Base Contract Additions are the prices for materials and work
                        above that included in the Base Contract and Construction
                        Specifications. These prices are eligible for a fixed price
                        contact when executed with a CCDC2 or CCDC14.
                      </p>
                    </b-col>
                    <b-col
                      align-self="end"
                      class="d-flex align-items-center justify-content-end"
                      cols="2"
                    >
                      <h4 class="text-primary">
                        {{
                          Number((additions_total_price_sqft)+cm_fee_additions_calc)
                            .toLocaleString('en-CA', {
                              minimumFractionDigits: 2,
                              style: 'currency',
                              currency: 'CAD',
                            })
                        }}
                      </h4>
                      <h4>/sqft</h4>
                    </b-col>
                  </b-row>
                </b-card-title>
                <b-row>
                  <b-col
                    align-self="end"
                    cols="12"
                    style="text-align: end; padding-bottom: 20px"
                  >
                    <b-button class="mr-1" variant="primary" @click="additionSetModal=true" :disabled="
                        quote.tfr.tfr_status == 'Quote Completed' ||
                          quote.opportunity.ccdc_signed == true || quote.tfr.tfr_status=='Archived'
                      ">
                      Add addition sets
                    </b-button>
                    <b-button
                      v-b-modal.options-modal
                      variant="primary"
                      :disabled="
                        quote.tfr.tfr_status == 'Quote Completed' ||
                          quote.opportunity.ccdc_signed == true || quote.tfr.tfr_status=='Archived'
                      "
                    >
                      Add Items
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <!-- start Cnstrn drag and drop table -->
                    <div class="row">
                      <div class="col-6">
                        <h4>Construction</h4></div>
                      <div class="col-6">
                        <div class="d-flex justify-content-end">
                          <!--         v-if="items.length > 0"-->
                          <div
                            class="d-flex align-items-center justify-content-end"
                            align-self="end"
                            style="text-align: end"
                          >
                            <b-dropdown
                              class="w-100 mb-1"
                              style="z-index: 13"
                              variant="outline-secondary"
                              text="Split Link"
                              checkbox-menu
                              allow-focus
                              dropdown
                            >
                              <template #button-content>
                                <span> <uil-columns /> Columns </span>
                              </template>
                              <b-dropdown-form class="w-100">
                                <b-form-group>
                                  <template #label>
                                    {{ baseSpecSelectedAll }}
                                    <b-form-checkbox
                                      v-model="allSelected"
                                      aria-describedby="flavours"
                                      aria-controls="flavours"
                                      @change="toggleAllDrop"
                                    >
                                      {{ allSelected ? 'Deselect All' : 'Select All' }}
                                    </b-form-checkbox>
                                    <b-dropdown-divider style="margin-top:3px" />
                                  </template>
                                  <b-form-checkbox
                                    v-for="(f, index) in fields"
                                    :key="'check' + index"
                                    v-model="displayColumnsCheck"
                                    :value="f.key"
                                  >{{ f.label }}
                                  </b-form-checkbox>
                                </b-form-group>
                                <b-dropdown-divider />
                                <div style="display: flex; align-items: end; justify-content: end">
                                  <b-button
                                    class="py-0"
                                    variant="outline-light"
                                    style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                    @click="resetCols('cnstr_add')"
                                  >Reset
                                  </b-button>
                                </div>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table-simple
                      class="table-item "
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 33.5vh"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fieldsOptions"
                          v-if="fO.key != 'actions' && showColumnsFiltered(fO.key,'cstrn_add')"
                          :key="'fo' + index"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      <!-- Draggable rows -->
                      <draggable
                        v-model="cstrOptions"
                        group="ctroption"
                        tag="tbody"
                        @change="onChange('option')"
                      >
                        <b-tr
                          v-for="item in cstrOptions"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br>
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span>
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('internal_note','cstrn_add')">
                            {{ item.internal_note }}
                          </b-td>
                          <b-td v-if="showColumnsFiltered('uniformat_code','cstrn_add')">
                            <div v-if="item.uniformat!=null">{{
                              searchUniformatCodeName('Construction', item.uniformat)
                            }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('is_base_spec','cstrn_add')">
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div
                              v-else
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              />
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('price_per_sqft','cstrn_add')">
                            <div
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                Number(item.price_per_sqft)
                                  .toLocaleString('en-CA', {
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_price','cstrn_add')">
                            <div
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                Number((item.total_price))
                                  .toLocaleString('en-CA', {
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('cost_per_sqft','cstrn_add')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                Number((item.cost_per_sqft))
                                  .toLocaleString('en-CA', {
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_cost','cstrn_add')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                Number((item.total_cost))
                                  .toLocaleString('en-CA', {
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a
                                v-b-modal.edit-options-modal
                                @click="editOption(item)"
                              >
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                >Edit</span>
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteOption(item)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end Cstrun drag and drop table -->
                    <!-- start FFE drag and drop table -->
                    <div class="row">
                      <div class="col-6">
                        <h4>FF&E</h4></div>
                      <div class="col-6">
                        <div class="d-flex justify-content-end">
                          <!--         v-if="items.length > 0"-->
                          <div
                              class="d-flex align-items-center justify-content-end"
                              align-self="end"
                              style="text-align: end"
                          >
                            <b-dropdown
                                class="w-100 mb-1"
                                style="z-index: 12;"
                                variant="outline-secondary"
                                text="Split Link"
                                checkbox-menu
                                allow-focus
                                dropdown
                            >
                              <template #button-content>
                                <span> <uil-columns /> Columns </span>
                              </template>
                              <b-dropdown-form class="w-100" style="color: red !important;">
                                <b-form-group>
                                  <template #label>
                                    {{ baseSpecSelectedAllFfe }}
                                    <b-form-checkbox
                                        v-model="allSelected"
                                        aria-describedby="flavours"
                                        aria-controls="flavours"
                                        @change="toggleAllDropFfe"
                                    >
                                      {{ allSelectedFfe ? 'Deselect All' : 'Select All' }}
                                    </b-form-checkbox>
                                    <b-dropdown-divider style="margin-top:3px" />
                                  </template>
                                  <b-form-checkbox
                                      v-for="(f, index) in fields"
                                      :key="'check' + index"
                                      v-model="displayColumnsCheckFfe"
                                      :value="f.key"
                                  >{{ f.label }}
                                  </b-form-checkbox>
                                </b-form-group>
                                <b-dropdown-divider />
                                <div style="display: flex; align-items: end; justify-content: end">
                                  <b-button
                                      class="py-0"
                                      variant="outline-light"
                                      style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                      @click="resetCols('ffe_add')"
                                  >Reset
                                  </b-button>
                                </div>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table-simple
                      class="table-item"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 33.5vh"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fieldsOptionsTermin"
                          v-if="fO.key != 'actions' && showColumnsFiltered(fO.key,'ffe_add')"
                          :key="'fop2' + index"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      <!-- Draggable rows -->
                      <draggable
                        v-model="ffeOptions"
                        group="ffeoption"
                        tag="tbody"
                        @change="onChange('option')"
                      >
                        <b-tr
                          v-for="item in ffeOptions"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br>
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span>
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('internal_note','ffe_add')">{{ item.internal_note }}</b-td>
                          <b-td v-if="showColumnsFiltered('uniformat_code','ffe_add')">
                            <div v-if="item.uniformat!=null">
                              {{ searchUniformatCodeName('FF&E', item.uniformat) }}
                            </div>
                          </b-td>
                          <b-td  v-if="showColumnsFiltered('is_base_spec','ffe_add')">
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div
                              v-else
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              />
                            </div>
                          </b-td>
                          <b-td  v-if="showColumnsFiltered('price_per_sqft','ffe_add')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                Number((item.price_per_sqft))
                                  .toLocaleString('en-CA', {
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                              }}
                            </div>
                          </b-td>
                          <b-td  v-if="showColumnsFiltered('total_price','ffe_add')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                Number((item.total_price))
                                  .toLocaleString('en-CA', {
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                              }}
                            </div>
                          </b-td>
                          <b-td  v-if="showColumnsFiltered('cost_per_sqft','ffe_add')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.cost_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td  v-if="showColumnsFiltered('total_cost','ffe_add')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_cost))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a
                                v-b-modal.edit-options-modal
                                @click="editOption(item)"
                              >
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                >Edit</span>
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteOption(item)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end FFE drag and drop table -->
                    <!-- start AVIT drag and drop table -->
                    <div class="row">
                      <div class="col-6">
                        <h4>AV / IT</h4></div>
                      <div class="col-6">
                        <div class="d-flex justify-content-end">
                          <!--         v-if="items.length > 0"-->
                          <div
                              class="d-flex align-items-center justify-content-end"
                              align-self="end"
                              style="text-align: end"
                          >
                            <b-dropdown
                                class="w-100 mb-1"
                                style="z-index: 11"
                                variant="outline-secondary"
                                text="Split Link"
                                checkbox-menu
                                allow-focus
                                dropdown
                            >
                              <template #button-content>
                                <span> <uil-columns /> Columns </span>
                              </template>
                              <b-dropdown-form class="w-100">
                                <b-form-group>
                                  <template #label>
                                    {{ baseSpecSelectedAllAvit }}
                                    <b-form-checkbox
                                        v-model="allSelected"
                                        aria-describedby="flavours"
                                        aria-controls="flavours"
                                        @change="toggleAllDropAvit"
                                    >
                                      {{ allSelectedAvit ? 'Deselect All' : 'Select All' }}
                                    </b-form-checkbox>
                                    <b-dropdown-divider style="margin-top:3px" />
                                  </template>
                                  <b-form-checkbox
                                      v-for="(f, index) in fields"
                                      :key="'check' + index"
                                      v-model="displayColumnsCheckAvit"
                                      :value="f.key"
                                  >{{ f.label }}
                                  </b-form-checkbox>
                                </b-form-group>
                                <b-dropdown-divider />
                                <div style="display: flex; align-items: end; justify-content: end">
                                  <b-button
                                      class="py-0"
                                      variant="outline-light"
                                      style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                      @click="resetCols('avit_add')"
                                  >Reset
                                  </b-button>
                                </div>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table-simple
                      class="table-item"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 33.5vh"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fieldsOptionsTermin"
                          v-if="fO.key != 'actions' && showColumnsFiltered(fO.key,'avit_add')"
                          :key="'fo3' + index"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      <!-- Draggable rows -->
                      <draggable
                        v-model="avitOptions"
                        group="avitoption"
                        tag="tbody"
                        @change="onChange('option')"
                      >
                        <b-tr
                          v-for="item in avitOptions"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br>
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span>
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('internal_note','avit_add')">{{ item.internal_note }}</b-td>
                          <b-td v-if="showColumnsFiltered('uniformat_code','avit_add')">
                            <div v-if="item.uniformat!=null">
                              {{ searchUniformatCodeName('AVIT', item.uniformat) }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('is_base_spec','avit_add')">
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div
                              v-else
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              />
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('price_per_sqft','avit_add')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                Number(Math.abs(item.price_per_sqft))
                                  .toLocaleString('en-CA', {
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_price','avit_add')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                Number((item.total_price))
                                  .toLocaleString('en-CA', {
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('cost_per_sqft','avit_add')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.cost_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_cost','avit_add')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_cost))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a
                                v-b-modal.edit-options-modal
                                @click="editOption(item)"
                              >
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                >Edit</span>
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteOption(item)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end AVIT drag and drop table -->
                    <!-- start SC drag and drop table -->
                    <div class="row">
                      <div class="col-6">
                        <h4>Soft Costs</h4>
                      </div>
                      <div class="col-6">
                        <div class="d-flex justify-content-end">
                          <!--         v-if="items.length > 0"-->
                          <div
                              class="d-flex align-items-center justify-content-end"
                              align-self="end"
                              style="text-align: end"
                          >
                            <b-dropdown
                                class="w-100 mb-1"
                                style="z-index: 11"
                                variant="outline-secondary"
                                text="Split Link"
                                checkbox-menu
                                allow-focus
                                dropdown
                            >
                              <template #button-content>
                                <span> <uil-columns /> Columns </span>
                              </template>
                              <b-dropdown-form class="w-100">
                                <b-form-group>
                                  <template #label>
                                    {{ baseSpecSelectedAllSc }}
                                    <b-form-checkbox
                                        v-model="allSelectedSc"
                                        aria-describedby="flavours"
                                        aria-controls="flavours"
                                        @change="toggleAllDropSc"
                                    >
                                      {{ allSelectedSc ? 'Deselect All' : 'Select All' }}
                                    </b-form-checkbox>
                                    <b-dropdown-divider style="margin-top:3px" />
                                  </template>
                                  <b-form-checkbox
                                      v-for="(f, index) in fields"
                                      :key="'check' + index"
                                      v-model="displayColumnsCheckAllSc"
                                      :value="f.key"
                                  >{{ f.label }}
                                  </b-form-checkbox>
                                </b-form-group>
                                <b-dropdown-divider />
                                <div style="display: flex; align-items: end; justify-content: end">
                                  <b-button
                                      class="py-0"
                                      variant="outline-light"
                                      style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                      @click="resetCols('sc_add')"
                                  >Reset
                                  </b-button>
                                </div>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table-simple
                      class="table-item"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 33.5vh"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fieldsOptions"
                          v-if="fO.key != 'actions' && showColumnsFiltered(fO.key,'sc_add')"
                          :key="'fo4' + index"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      <!-- Draggable rows -->
                      <draggable
                        v-model="scOptions"
                        group="scoption"
                        tag="tbody"
                        @change="onChange('option')"
                      >
                        <b-tr
                          v-for="item in scOptions"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br>
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span>
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('internal_note','sc_add')">{{ item.internal_note }}</b-td>
                          <b-td  v-if="showColumnsFiltered('uniformat_code','sc_add')">
                            <div v-if="item.uniformat!=null">{{
                              searchUniformatCodeName('Soft Costs', item.uniformat)
                            }}
                            </div>
                          </b-td>

                          <b-td v-if="showColumnsFiltered('is_base_spec','sc_add')">
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div
                              v-else
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              />
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('price_per_sqft','sc_add')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.price_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_price','sc_add')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_price))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('cost_per_sqft','sc_add')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.cost_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td  v-if="showColumnsFiltered('total_cost','sc_add')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_cost))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a
                                v-b-modal.edit-options-modal
                                @click="editOption(item)"
                              >
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                >Edit</span>
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteOption(item)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end CS drag and drop table -->
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col
                align-self="start"
                cols="11"
              >
                <uil-money-bill
                  class="logo"
                  size="15px"
                />
                Cash Allowances
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              >
                <!-- {{
                  Number(cashAllowanceSubtotal / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }} -->
              </b-col>
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <b-card-title>
                  <b-row>
                    <b-col cols="10">
                      <h2>Cash Allowances</h2>
                      <p style="color: #6e6b7b; font-size: 12px">
                        A cash allowance is an estimate for materials or work that is
                        required, but which cannot be adequately scoped or priced without
                        further details. When the materials or work can be accurately
                        priced, per the CCDC, the Contract Price shall be adjusted by
                        Change Order to provide for any difference between the cash
                        allowance and the actual cost of the work performed.
                      </p>
                    </b-col>
                    <b-col
                      align-self="end"
                      class="d-flex align-items-center justify-content-end"
                      cols="2"
                    >
                      <h4 class="text-primary">
                        {{
                          Number((cashAllowanceSubtotal / quote.sqft)+cm_fee_allowances_calc)
                            .toLocaleString(
                              'en-CA',
                              {
                                minimumFractionDigits: 2,
                                style: 'currency',
                                currency: 'CAD',
                              }
                            )
                        }}
                      </h4>
                      <h4>/sqft</h4>
                    </b-col>
                  </b-row>
                </b-card-title>
                <b-row>
                  <b-col
                    align-self="end"
                    cols="12"
                    style="text-align: end; padding-bottom: 20px"
                  >
                    <b-button
                      v-b-modal.create-allow
                      variant="primary"
                      :disabled="
                        quote.tfr.tfr_status == 'Quote Completed' ||
                          quote.opportunity.ccdc_signed == true || quote.tfr.tfr_status=='Archived'
                      "
                    >
                      Add Items
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <!-- start Cnstrn drag and drop table -->
                    <div class="row">
                      <div class="col-6">
                        <h4>Construction</h4>
                      </div>
                      <div class="col-6">
                        <div class="d-flex justify-content-end">
                          <!--         v-if="items.length > 0"-->
                          <div
                              class="d-flex align-items-center justify-content-end"
                              align-self="end"
                              style="text-align: end"
                          >
                            <b-dropdown
                                class="w-100 mb-1"
                                style="z-index: 11"
                                variant="outline-secondary"
                                text="Split Link"
                                checkbox-menu
                                allow-focus
                                dropdown
                            >
                              <template #button-content>
                                <span> <uil-columns /> Columns </span>
                              </template>
                              <b-dropdown-form class="w-100">
                                <b-form-group>
                                  <template #label>
                                    {{ baseSpecSelectedAllAllow }}
                                    <b-form-checkbox
                                        v-model="allSelectedAllow"
                                        aria-describedby="flavours"
                                        aria-controls="flavours"
                                        @change="toggleAllDropAllow"
                                    >
                                      {{ allSelectedSc ? 'Deselect All' : 'Select All' }}
                                    </b-form-checkbox>
                                    <b-dropdown-divider style="margin-top:3px" />
                                  </template>
                                  <b-form-checkbox
                                      v-for="(f, index) in fields"
                                      :key="'check' + index"
                                      v-model="displayColumnsCheckAllow"
                                      :value="f.key"
                                  >{{ f.label }}
                                  </b-form-checkbox>
                                </b-form-group>
                                <b-dropdown-divider />
                                <div style="display: flex; align-items: end; justify-content: end">
                                  <b-button
                                      class="py-0"
                                      variant="outline-light"
                                      style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                      @click="resetCols('cnstr_allow')"
                                  >Reset
                                  </b-button>
                                </div>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table-simple
                      class="table-item"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 33.5vh"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fieldsOptions"
                          v-if="fO.key != 'actions' && showColumnsFiltered(fO.key,'cnstr_allow')"
                          :key="'fo5' + index"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      <!-- Draggable rows -->
                      <draggable
                        v-model="cstrAllows"
                        group="cstrAllows"
                        tag="tbody"
                        @change="onChange('Allowances')"
                      >
                        <b-tr
                          v-for="item in cstrAllows"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br>
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span>
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('internal_note','cnstr_allow')">{{ item.internal_note }}</b-td>
                          <b-td v-if="showColumnsFiltered('uniformat_code','cnstr_allow')">
                            <div v-if="item.uniformat!=null">{{
                              searchUniformatCodeName('Construction', item.uniformat)
                            }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('is_base_spec','cnstr_allow')">
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div
                              v-else
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              />
                            </div>
                          </b-td>
                          <b-td  v-if="showColumnsFiltered('price_per_sqft','cnstr_allow')">
                            <div
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.price_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_price','cnstr_allow')">
                            <div
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_price))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td  v-if="showColumnsFiltered('cost_per_sqft','cnstr_allow')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.cost_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td  v-if="showColumnsFiltered('total_cost','cnstr_allow')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_cost))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a @click="openUpdateAllowanceModal(item)">
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                >Edit</span>
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteAllowance(item)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end Cstrun drag and drop table -->
                    <!-- start FFE drag and drop table -->
                    <div class="row">
                      <div class="col-6">
                        <h4>FF&E</h4>
                      </div>
                      <div class="col-6">
                        <div class="d-flex justify-content-end">
                          <!--         v-if="items.length > 0"-->
                          <div
                              class="d-flex align-items-center justify-content-end"
                              align-self="end"
                              style="text-align: end"
                          >
                            <b-dropdown
                                class="w-100 mb-1"
                                style="z-index: 11"
                                variant="outline-secondary"
                                text="Split Link"
                                checkbox-menu
                                allow-focus
                                dropdown
                            >
                              <template #button-content>
                                <span> <uil-columns /> Columns </span>
                              </template>
                              <b-dropdown-form class="w-100">
                                <b-form-group>
                                  <template #label>
                                    {{ baseSpecSelectedAllFfeAllow }}
                                    <b-form-checkbox
                                        v-model="allSelectedFfeAllow"
                                        aria-describedby="flavours"
                                        aria-controls="flavours"
                                        @change="toggleAllDropFfeAllow"
                                    >
                                      {{ allSelectedFfeAllow ? 'Deselect All' : 'Select All' }}
                                    </b-form-checkbox>
                                    <b-dropdown-divider style="margin-top:3px" />
                                  </template>
                                  <b-form-checkbox
                                      v-for="(f, index) in fields"
                                      :key="'check' + index"
                                      v-model="displayColumnsCheckFfeAllow"
                                      :value="f.key"
                                  >{{ f.label }}
                                  </b-form-checkbox>
                                </b-form-group>
                                <b-dropdown-divider />
                                <div style="display: flex; align-items: end; justify-content: end">
                                  <b-button
                                      class="py-0"
                                      variant="outline-light"
                                      style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                      @click="resetCols('ffe_allow')"
                                  >Reset
                                  </b-button>
                                </div>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table-simple
                      class="table-item"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 33.5vh"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fieldsOptionsTermin"
                          v-if="fO.key != 'actions' && showColumnsFiltered(fO.key,'ffe_allow')"
                          :key="'fo6' + index"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      <!-- Draggable rows -->
                      <draggable
                        v-model="ffeAllows"
                        group="ffeAllows"
                        tag="tbody"
                        @change="onChange('Allowances')"
                      >
                        <b-tr
                          v-for="item in ffeAllows"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br>
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span>
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('internal_note','ffe_allow')">{{ item.internal_note }}</b-td>
                          <b-td v-if="showColumnsFiltered('uniformat_code','ffe_allow')">
                            <div v-if="item.uniformat!=null">
                              {{ searchUniformatCodeName('FF&E', item.uniformat) }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('is_base_spec','ffe_allow')">
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div
                              v-else
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              />
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('price_per_sqft','ffe_allow')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.price_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_price','ffe_allow')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_price))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('cost_per_sqft','ffe_allow')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.cost_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_cost','ffe_allow')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_cost))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a @click="openUpdateAllowanceModal(item)">
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                >Edit</span>
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteAllowance(item)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end FFE drag and drop table -->
                    <!-- start AVIT drag and drop table -->
                    <div class="row">
                      <div class="col-6">
                        <h4>AV / IT</h4>
                      </div>
                      <div class="col-6">
                        <div class="d-flex justify-content-end">
                          <!--         v-if="items.length > 0"-->
                          <div
                              class="d-flex align-items-center justify-content-end"
                              align-self="end"
                              style="text-align: end"
                          >
                            <b-dropdown
                                class="w-100 mb-1"
                                style="z-index: 11"
                                variant="outline-secondary"
                                text="Split Link"
                                checkbox-menu
                                allow-focus
                                dropdown
                            >
                              <template #button-content>
                                <span> <uil-columns /> Columns </span>
                              </template>
                              <b-dropdown-form class="w-100">
                                <b-form-group>
                                  <template #label>
                                    {{ baseSpecSelectedAllAvitAllow }}
                                    <b-form-checkbox
                                        v-model="allSelectedAvitAllow"
                                        aria-describedby="flavours"
                                        aria-controls="flavours"
                                        @change="toggleAllDropAvitAllow"
                                    >
                                      {{ allSelectedAvitAllow ? 'Deselect All' : 'Select All' }}
                                    </b-form-checkbox>
                                    <b-dropdown-divider style="margin-top:3px" />
                                  </template>
                                  <b-form-checkbox
                                      v-for="(f, index) in fields"
                                      :key="'check' + index"
                                      v-model="displayColumnsCheckAvitAllow"
                                      :value="f.key"
                                  >{{ f.label }}
                                  </b-form-checkbox>
                                </b-form-group>
                                <b-dropdown-divider />
                                <div style="display: flex; align-items: end; justify-content: end">
                                  <b-button
                                      class="py-0"
                                      variant="outline-light"
                                      style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                      @click="resetCols('avit_allow')"
                                  >Reset
                                  </b-button>
                                </div>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table-simple
                      class="table-item"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 33.5vh"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fieldsOptionsTermin"
                          v-if="fO.key != 'actions' && showColumnsFiltered(fO.key, 'avit_allow')"
                          :key="'fo7' + index"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      <!-- Draggable rows -->
                      <draggable
                        v-model="avitAllows"
                        group="avitAllows"
                        tag="tbody"
                        @change="onChange('Allowances')"
                      >
                        <b-tr
                          v-for="item in avitAllows"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br>
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span>
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('internal_note', 'avit_allow')">{{ item.internal_note }}</b-td>
                          <b-td v-if="showColumnsFiltered('uniformat_code', 'avit_allow')">
                            <div v-if="item.uniformat!=null">
                              {{ searchUniformatCodeName('AVIT', item.uniformat) }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('is_base_spec', 'avit_allow')">
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div
                              v-else
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              />
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('price_per_sqft', 'avit_allow')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.price_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_price', 'avit_allow')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_price))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('cost_per_sqft', 'avit_allow')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.cost_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_cost', 'avit_allow')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_cost))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a @click="openUpdateAllowanceModal(item)">
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                >Edit</span>
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteAllowance(item)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end AVIT drag and drop table -->
                    <!-- start SC drag and drop table -->
                    <div class="row">
                      <div class="col-6">
                        <h4>Soft Costs</h4>
                      </div>
                      <div class="col-6">
                        <div class="d-flex justify-content-end">
                          <!--         v-if="items.length > 0"-->
                          <div
                              class="d-flex align-items-center justify-content-end"
                              align-self="end"
                              style="text-align: end"
                          >
                            <b-dropdown
                                class="w-100 mb-1"
                                style="z-index: 11"
                                variant="outline-secondary"
                                text="Split Link"
                                checkbox-menu
                                allow-focus
                                dropdown
                            >
                              <template #button-content>
                                <span> <uil-columns /> Columns </span>
                              </template>
                              <b-dropdown-form class="w-100">
                                <b-form-group>
                                  <template #label>
                                    {{ baseSpecSelectedAllScAllow }}
                                    <b-form-checkbox
                                        v-model="allSelectedScAllow"
                                        aria-describedby="flavours"
                                        aria-controls="flavours"
                                        @change="toggleAllDropScAllow"
                                    >
                                      {{ allSelectedScAllow ? 'Deselect All' : 'Select All' }}
                                    </b-form-checkbox>
                                    <b-dropdown-divider style="margin-top:3px" />
                                  </template>
                                  <b-form-checkbox
                                      v-for="(f, index) in fields"
                                      :key="'check' + index"
                                      v-model="displayColumnsCheckScAllow"
                                      :value="f.key"
                                  >{{ f.label }}
                                  </b-form-checkbox>
                                </b-form-group>
                                <b-dropdown-divider />
                                <div style="display: flex; align-items: end; justify-content: end">
                                  <b-button
                                      class="py-0"
                                      variant="outline-light"
                                      style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                      @click="resetCols('sc_allow')"
                                  >Reset
                                  </b-button>
                                </div>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>

                    <b-table-simple
                      class="table-item"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 33.5vh"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fieldsOptions"
                          v-if="fO.key != 'actions' && showColumnsFiltered(fO.key, 'sc_allow')"
                          :key="'fo6' + index"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      <!-- Draggable rows -->
                      <draggable
                        v-model="scAllows"
                        group="scAllows"
                        tag="tbody"
                        @change="onChange('Allowances')"
                      >
                        <b-tr
                          v-for="item in scAllows"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br>
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span>
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('internal_note', 'sc_allow')">{{ item.internal_note }}</b-td>
                          <b-td v-if="showColumnsFiltered('uniformat_code', 'sc_allow')">
                            <div v-if="item.uniformat!=null">{{
                              searchUniformatCodeName('Soft Costs', item.uniformat)
                            }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('is_base_spec', 'sc_allow')">
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div
                              v-else
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              />
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('price_per_sqft', 'sc_allow')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.price_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_price', 'sc_allow')">
                            <div
                              v-if="item.budget_group != 'Construction'"
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                                [(item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_price))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('cost_per_sqft', 'sc_allow')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.cost_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td v-if="showColumnsFiltered('total_cost', 'sc_allow')">
                            <div
                              class="cost-line"
                              style="text-align: center"
                            >
                              <uil-arrow-growth
                                v-if="item.is_adjustment == true"
                                style="color: #ff9f43"
                              />
                              {{
                                [(item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_cost))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a @click="openUpdateAllowanceModal(item)">
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                >Edit</span>
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteAllowance(item)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end CS drag and drop table -->
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col
                align-self="start"
                cols="11"
              >
                <uil-setting
                  class="logo"
                  size="15px"
                />
                Settings
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <template v-if="!isBusy">
              <div
                v-if="showFFEAlert == true || showAVITAlert == true"
                class="alert-warning"
              >
                <span>
                  <UilExclamationTriangle
                    fill="#FF9F43"
                    size="15px"
                  />
                  Additions are still being included in a disabled budget group.</span>
              </div>
            </template>
            <settings-assumptions-exclusions
              :quote="quote"
              @optionsChanged="settingsChanged"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <b-col
                align-self="start"
                cols="11"
              >
                <uil-history
                  class="logo"
                  size="15px"
                />
                Quote Activity Log
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <activity-log :quote="quote" />
          </b-tab>
          <template #tabs-end>
            <comment-section
              ref="commentComponent"
              :comments="comments"
              :demolition-image="quote.demolition_image"
              :quote-id="quoteId"
              style="max-width: none"
              @commentChanged="commentChanged"
            />
          </template>
        </b-tabs>
      </b-col>
    </b-row>

    <n-options-create-modal
      :quote-id="quoteId"
      :sqft="quote.sqft"
      :construction-items="quoteConstructionItems"
      :base_spec_version="base_spec_version_c"
      @updatedOptions="childOptions"
    />
    <n-options-edit-modal
      :option-to-edit="optionToEdit"
      :quote-id="quoteId"
      :sqft="quote.sqft"
      :construction-items="quoteConstructionItems"
      :base_spec_version="base_spec_version_c"
      @updatedOptions="childOptions"
      @delete_option="deleteOption"
    />
    <site-conditions-create-modal
      :quote-id="quoteId"
      :sqft="quote.sqft"
      @updatedSiteConditions="childSiteConditions"
    />
    <site-conditions-edit-modal
      :quote-id="quoteId"
      :site-condition-to-edit="siteConditionToEdit"
      :sqft="quote.sqft"
      @updatedSiteConditions="childSiteConditions"
    />
    <create-allow
      :quote-id="quoteId"
      :sqft="quote.sqft"
      :construction-items="quoteConstructionItems"
      :base_spec_version="base_spec_version_c"
      @updatedAllowances="childAllowances"
    />
    <edit-allow
      :cash_allowance_to_edit="selected_allowance"
      :quote-id="quoteId"
      :sqft="quote.sqft"
      :construction-items="quoteConstructionItems"
      :base_spec_version="base_spec_version_c"
      @updatedAllowances="childAllowances"
      @delete_cash_allowance="deleteAllowance"
    />
    <b-modal v-model="additionSetModal" title="Add Addition set" no-close-on-backdrop centered size="lg" @close="closeAdditionSetModal">
      <b-alert show variant="warning" style="height: 65px"><div class="mx-2">
        Please note all Additions from the selected Set will be added regardless of existing Additions. This could result in duplicate entries. Please confirm the result after applying.
      </div></b-alert>
      <b-row>
                      <b-col :cols="addition_set_selected === 0 || addition_set_selected===null ? 12 : 10" class="my-0">
                        <validation-provider
                            #default="{ errors }"
                            name="Addition Set"
                            rules="required"
                        >
                          <div>
                            <label>
                              Select Addition Set
                            </label>
                            <v-select
                                style="z-index: 10000"
                                label="name"
                                :options="addition_sets"
                                item-text="name"
                                :reduce="(option) => option.id"
                                :clearable="false"
                                v-model="addition_set_selected"
                                max-height="100px"
                                class="style-chooser-addition"
                                placeholder="Select Addition Set"
                            />
                          </div>
                          <small class="text-danger" v-if="errors[0]"
                          >{{ errors[0] }}<br
                          /></small>
                        </validation-provider>
                      </b-col>
                      <b-col  class="p-0 m-0" v-if="addition_set_selected != 0 && addition_set_selected!=null" :cols="addition_set_selected == 0 ? 0 : 2">
                        <b-button style="margin-top:35px" class="ml-1" @click="gotoAdditionSet()">
                          Review</b-button
                        >
                      </b-col>
      </b-row>
      <template #modal-footer>
        <div
             class="float-right">
          <b-button @click="closeAdditionSetModal()" class="ml-1">
            Cancel
          </b-button>
          &nbsp;
          <b-button
              variant="primary"
              :disabled="addition_set_selected !== 0 && addition_set_selected===null||is_adding_addition"
              @click="addAdditionSet()"
          >
            Add additions
          </b-button>
        </div>
      </template>
    </b-modal>
    </b-modal>
  </section>
</template>

<script>
import {
  UilBill,
  UilBox,
  UilCheckCircle,
  UilEditAlt,
  UilExclamationTriangle,
  UilHardHat,
  UilPlus,
  UilRuler,
  UilSuitcase,
  UilTape,
  UilWifi,
  UilSetting,
  UilConstructor,
  UilArrowGrowth,
  UilHistory,
  UilTrashAlt,
  UilMoneyBill,
  UilColumns,
} from '@iconscout/vue-unicons'
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTableSimple,
  BTabs,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTooltip,
  BTr,
  VBToggle,
  BBadge, BFormGroup, BDropdownDivider, BDropdownForm, BModal, BAlert
} from 'bootstrap-vue'
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import UilChair from '@/assets/CustomIcons/uil-chair'
import CommentSection from '../Comments/Components/Comment.vue'
import NOptionsCreateModal from '../Options/Components/NCreateModal.vue'
import NOptionsEditModal from '../Options/Components/NEditModal.vue'
import SiteConditionsCreateModal from '../SiteConditions/Components/CreateModal.vue'
import SiteConditionsEditModal from '../SiteConditions/Components/EditModal.vue'
import SettingsAssumptionsExclusions from './Components/SettingsAssumptionsExclusionsTab.vue'
import ConstructionBreakdowns from './Components/ConstructionBreakdownsTab.vue'
import ActivityLog from './Components/ActivityLogTab.vue'
import QuoteInfoHeader from './Components/QuoteInfoHeader.vue'
import CreateAllow from '../CashAllowances/Components/CreateAllow.vue'
import EditAllow from '../CashAllowances/Components/EditAllow.vue'
import EditSummarySection from './Components/EditSummarySection.vue'
import EditFfeTable from './Components/EditFfeTable.vue'
import EditAvitTable from './Components/EditAvitTable.vue'
import uniformat from '@/assets/objects/uniformat-codes.json'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BDropdownDivider,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BFormCheckbox,
    BTableSimple,
    BButton,
    BTable,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
    CommentSection,
    UilRuler,
    UilSuitcase,
    UilBill,
    UilTape,
    UilBox,
    UilWifi,
    UilHardHat,
    UilPlus,
    UilCheckCircle,
    UilEditAlt,
    UilExclamationTriangle,
    UilMoneyBill,
    BFormDatepicker,
    BInputGroupAppend,
    BFormInput,
    BInputGroup,
    BTabs,
    BTab,
    NOptionsCreateModal,
    NOptionsEditModal,
    SiteConditionsCreateModal,
    SiteConditionsEditModal,
    BPagination,
    BCardTitle,
    JsonExcel,
    UilSetting,
    SettingsAssumptionsExclusions,
    ConstructionBreakdowns,
    BBadge,
    UilChair,
    UilConstructor,
    UilArrowGrowth,
    UilHistory,
    ActivityLog,
    UilTrashAlt,
    UilChair,
    QuoteInfoHeader,
    draggable,
    CreateAllow,
    EditAllow,
    EditSummarySection,
    EditFfeTable,
    EditAvitTable,
    UilColumns,
    BDropdownForm,
    BModal,
    BAlert,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      quoteId: this.$route.params.quoteid,
      quote: {
        opportunity: {},
        comments: {},
        construction_cost_per_sqft: 0,
        construction_buffer: 0,
      },
      permit_fees: 0,
      tabIndex: 0,
      isBusy: true,
      comments: {},
      show: true,
      newComment: '',
      showFFEAlert: false,
      showAVITAlert: false,
      showConstructionAlert: false,
      fieldsOptions: [
        {
          key: 'name',
          label: 'ITEM',
          sortable: true,
        },
        {
          key: 'internal_note',
          label: 'INTERNAL NOTE',
          sortable: false,
        },
        {
          key: 'uniformat_code',
          label: 'tender code',
          sortable: false,
        },
        {
          key: 'is_base_spec',
          label: 'HIDDEN',
          sortable: true,
        },
        {
          key: 'price_per_sqft',
          label: 'Price / SQFT',
          sortable: false,
        },
        {
          key: 'total_price',
          label: 'Total Price',
          sortable: true,
        },
        {
          key: 'cost_per_sqft',
          label: 'COGS / SQFT',
          sortable: true,
        },
        {
          key: 'total_cost',
          label: 'Total COGS',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'ACTIONS',
          tdClass: this.visibleActions,
        },
      ],
      fieldsOptionsTermin: [
        {
          key: 'name',
          label: 'ITEM',
          sortable: true,
        },
        {
          key: 'internal_note',
          label: 'INTERNAL NOTE',
          sortable: false,
        },
        {
          key: 'uniformat_code',
          label: 'tender code',
          sortable: false,
        },
        {
          key: 'is_base_spec',
          label: 'HIDDEN',
          sortable: true,
        },
        {
          key: 'price_per_sqft',
          label: 'Price / SQFT',
          sortable: false,
        },
        {
          key: 'total_price',
          label: 'Total Price (w/ D&I)',
          sortable: true,
        },
        {
          key: 'cost_per_sqft',
          label: 'COGS / SQFT',
          sortable: true,
        },
        {
          key: 'total_cost',
          label: 'Total COGS',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'ACTIONS',
          tdClass: this.visibleActions,
        },
      ],
      breakdownFields: [
        {
          key: 'name',
          label: 'ITEM',
          sortable: true,
        },
        {
          key: 'qty',
          label: 'QTY',
          sortable: false,
        },
        {
          key: 'total_cost',
          label: 'Cost',
          sortable: true,
        },
        {
          key: 'cost_per_sqft',
          label: '$/sqft',
          sortable: true,
        },
      ],
      FFEbreakdownFields: [
        {
          key: 'name',
          label: 'ELEMENT',
          sortable: false,
          thClass: 'text-left',
        },
        {
          key: 'supply_price',
          label: 'Price / Unit',
          sortable: false,
          thClass: 'text-left',
        },
        {
          key: 'unit_delivery_install',
          label: 'Delivery and install / unit',
          sortable: false,
          thClass: 'text-left',
        },
        {
          key: 'qty',
          label: 'quantity',
          sortable: true,
          thClass: 'text-left',
        },
        {
          key: 'total_price',
          label: 'Total Price (w/ D&I)',
          sortable: true,
          thClass: 'text-left',
        },
      ],
      AVITbreakdownFields: [
        {
          key: 'name',
          label: 'ELEMENT',
          sortable: false,
          thClass: 'text-left',
        },
        {
          key: 'supply_price',
          label: 'Price / Unit',
          sortable: false,
          thClass: 'text-left',
        },
        {
          key: 'unit_delivery_install',
          label: 'Delivery and install / unit',
          sortable: false,
          thClass: 'text-left',
        },
        {
          key: 'qty',
          label: 'quantity',
          sortable: true,
          thClass: 'text-left',
        },
        {
          key: 'total_price',
          label: 'Total Price (w/ D&I)',
          sortable: true,
          thClass: 'text-left',
        },
      ],
      options: [],
      siteConditions: [],
      constructionSpecs: [],
      optionToEdit: [],
      optionsPerPage: 15,
      optionsCurrentPage: 1,
      siteConditionToEdit: [],
      siteConditionsPerPage: 15,
      siteConditionsCurrentPage: 1,
      ffeSpecs: [],
      ffePerPage: 15,
      ffeCurrentPage: 1,
      ffeFilter: null,
      avitSpecs: [],
      avitPerPage: 15,
      avitCurrentPage: 1,
      avitFilter: null,
      stickyHeader: true,
      noCollapse: true,
      quoteConstructionItems: [],
      originalConstructionBuffer: 0,
      originalDesignBuffer: 0,
      originalConstructionPmBuffer: 0,
      originalLanguage: '',
      originalAvItToggle: true,
      originalFfeToggle: true,
      assumptionsExclusionsChanged: false,
      buttonDiscard: false,
      originalunion_labour_free: false,
      originalraised_access_floor_clear: false,
      originalffe_clear: false,
      originalmeets_building_code: false,
      originalbuilding_equipment_good_condition: false,
      originalbuilt_demising_interior_partitions: false,
      originalhazardous_material_clear: false,
      originaldemolition: false,
      originalaccess_included: false,
      originalbas_systems_included: false,
      originalelectrical_panel_transformer_included: false,
      originaladitional_demising_walls_included: false,
      originalperimeter_bulkhead_included: false,
      originalblinds_included: false,
      originalsuite_entry_doors_included: false,
      originalwashroom_work_included: false,
      originalelevator_lobby_work_included: false,
      originalabatement_hazardous_material_included: false,
      originallandlord_required_reviews_included: false,
      childComment: '',
      discardMessage: '',
      // filter vars
      cstrOptions: [],
      ffeOptions: [],
      avitOptions: [],
      scOptions: [],
      // filter cash allowances vars
      cstrAllows: [],
      ffeAllows: [],
      avitAllows: [],
      scAllows: [],
      notEditedQuote: [],
      swap_specifications: [],
      deleted_specification_swap: [],
      // additions totals
      additions_total_price_sqft: 0,
      // additions temp vars
      additions: [],
      deleted_additions: [],
      savingQuote: false,
      // cash allowances temp vars
      allowances: [],
      cash_allowances: [],
      deleted_cash_allowances: [],
      selected_allowance: [],
      // * variable to save cashAllow price sqft
      cashAllowanceSubtotal: 0,
      // * uniformat codes object
      uniformat_codes: uniformat,
      //Display columns variables
      displayColumnsCheck: [
        'name',
        'internal_note',
        'uniformat_code',
        'is_base_spec',
        'total_price',
      ],
      displayColumnsCheckFfe: [
        'name',
        'internal_note',
        'uniformat_code',
        'is_base_spec',
        'total_price',
      ],
      displayColumnsCheckAvit: [
        'name',
        'internal_note',
        'uniformat_code',
        'is_base_spec',
        'total_price',
      ],
      displayColumnsCheckAllow: [
        'name',
        'internal_note',
        'uniformat_code',
        'is_base_spec',
        'total_price',
      ],
      displayColumnsCheckFfeAllow: [
        'name',
        'internal_note',
        'uniformat_code',
        'is_base_spec',
        'total_price',
      ],
      displayColumnsCheckAvitAllow: [
        'name',
        'internal_note',
        'uniformat_code',
        'is_base_spec',
        'total_price',
      ],
      displayColumnsCheckScAllow: [
        'name',
        'internal_note',
        'uniformat_code',
        'is_base_spec',
        'total_price',
      ],
      displayColumnsCheckAllSc: [
        'name',
        'internal_note',
        'uniformat_code',
        'is_base_spec',
        'total_price',
      ],
      fields: [
        {
          label: 'Internal Note',
          key: 'internal_note',
        },
        {
          label: 'Uniformat',
          key: 'uniformat_code',
        },
        {
          label: 'Hidden',
          key: 'is_base_spec',
        },
        {
          label: 'Price / SQFT',
          key: 'price_per_sqft',
        },
        {
          label: 'Total Price',
          key: 'total_price',
        },
        {
          label: 'COGS / sqft',
          key: 'cost_per_sqft',
        },
        {
          label: 'Total COGS',
          key: 'total_cost',
        },
      ],
      // all selected variables for display/hide columns
      allSelected: false,
      allSelectedFfe: false,
      allSelectedAvit: false,
      allSelectedFfeAllow: false,
      allSelectedAvitAllow: false,
      allSelectedAllow: false,
      allSelectedScAllow: false,
      allSelectedSc: false,
      // additions sets modal
      additionSetModal: false,
      addition_set_selected: null,
      is_adding_addition:false,
      addition_sets:  [
        {
          name: 'Clearspace Base Spec',
          id: 0,
        },
      ],
    }
  },
  computed: {
    optionRows() {
      return this.options.length
    },
    siteConditionRows() {
      return this.siteConditions.length
    },
    ffeRows() {
      return this.ffeSpecs.length
    },
    avitRows() {
      return this.avitSpecs.length
    },
    checkHiddenFFE() {
      return this.quote.options_ffe_price_per_sqft != 0
    },
    checkHiddenAVIT() {
      return this.quote.options_avit_price_per_sqft != 0
    },
    checkHiddenConstruction() {
      return (
        this.quote.site_conditions_construction_price_per_sqft != 0
          || this.quote.options_construction_price_per_sqft != 0
      )
    },
    optionsAvItSubtotal() {
      let sum = 0
      this.options
        .filter(n => n.budget_group == 'AV/IT' && n.is_base_spec == true)
        .forEach(e => {
          sum += parseFloat(e.total_price)
        })
      return parseFloat(sum)
    },
    optionsFfeSubtotal() {
      let sum = 0
      this.options
        .filter(n => n.budget_group == 'FF&E' && n.is_base_spec == true)
        .forEach(e => {
          sum += parseFloat(e.total_price)
        })
      return parseFloat(sum)
    },
    optionsConstructionSubtotal() {
      let sum = 0
      this.options
        .filter(
          n => n.budget_group == 'Construction' && n.is_base_spec == true,
        )
        .forEach(e => {
          sum += parseFloat(e.total_price)
        })
      return parseFloat(sum)
    },
    siteConditionsConstructionSubtotal() {
      let sum = 0
      this.siteConditions
        .filter(
          n => n.budget_group == 'Construction' && n.is_base_spec == true,
        )
        .forEach(e => {
          sum += parseFloat(e.total_price)
        })
      return parseFloat(sum)
    },
    hasComment() {
      if (this.childComment != '') {
        return true
      }
      return false
    },
    quoteHasChanged() {
      return (
        this.originalConstructionBuffer != this.quote.construction_buffer
          || this.originalDesignBuffer != this.quote.design_ame_buffer
          || this.originalConstructionPmBuffer
          != this.quote.construction_pm_buffer
          || this.originalLanguage != this.quote.language
          || this.originalAvItToggle != this.quote.avit_toggle
          || this.originalFfeToggle != this.quote.ffe_toggle
          || this.originalunion_labour_free
          != this.quote.assumptions_exclusions.union_labour_free
          || this.originalraised_access_floor_clear
          != this.quote.assumptions_exclusions.raised_access_floor_clear
          || this.originalffe_clear != this.quote.assumptions_exclusions.ffe_clear
          || this.originalmeets_building_code
          != this.quote.assumptions_exclusions.meets_building_code
          || this.originalbuilding_equipment_good_condition
          != this.quote.assumptions_exclusions.building_equipment_good_condition
          || this.originalbuilt_demising_interior_partitions
          != this.quote.assumptions_exclusions
            .built_demising_interior_partitions
          || this.originalhazardous_material_clear
          != this.quote.assumptions_exclusions.hazardous_material_clear
          || this.originaldemolition
          != this.quote.assumptions_exclusions.demolition
          || this.originalaccess_included
          != this.quote.assumptions_exclusions.access_included
          || this.originalbas_systems_included
          != this.quote.assumptions_exclusions.bas_systems_included
          || this.originalelectrical_panel_transformer_included
          != this.quote.assumptions_exclusions
            .electrical_panel_transformer_included
          || this.originaladitional_demising_walls_included
          != this.quote.assumptions_exclusions.aditional_demising_walls_included
          || this.originalperimeter_bulkhead_included
          != this.quote.assumptions_exclusions.perimeter_bulkhead_included
          || this.originalblinds_included
          != this.quote.assumptions_exclusions.blinds_included
          || this.originalsuite_entry_doors_included
          != this.quote.assumptions_exclusions.suite_entry_doors_included
          || this.originalwashroom_work_included
          != this.quote.assumptions_exclusions.washroom_work_included
          || this.originalelevator_lobby_work_included
          != this.quote.assumptions_exclusions.elevator_lobby_work_included
          || this.originalabatement_hazardous_material_included
          != this.quote.assumptions_exclusions
            .abatement_hazardous_material_included
          || this.originallandlord_required_reviews_included
          != this.quote.assumptions_exclusions.landlord_required_reviews_included
      )
    },
    discardModalMessage() {
      if (this.hasComment && this.quoteHasChanged) {
        return 'It looks like you have unsaved changes and comments on this page.'
      } if (this.hasComment) {
        return 'It looks like you have unsaved comments on this page.'
      }
      return 'It looks like you have unsaved changes on this page.'
    },
    montrealAddress() {
      if (this.quote.language == 'fr') {
        return '1325-1010 rue de la Gauchetière Ouest'
      }
      return '1325-1010 de la Gauchetière West Street'
    },
    construction_pm_buffer: {
      get() {
        return parseFloat(this.quote.construction_pm_buffer)
          .toFixed(2)
      },
      set(newValue) {
        this.quote.construction_pm_buffer = newValue
      },
    },
    design_ame_buffer: {
      get() {
        return parseFloat(this.quote.design_ame_buffer)
          .toFixed(2)
      },
      set(newValue) {
        this.quote.design_ame_buffer = newValue
      },
    },
    construction_buffer: {
      get() {
        return parseFloat(this.quote.construction_buffer)
          .toFixed(2)
      },
      set(newValue) {
        this.quote.construction_buffer = newValue
      },
    },
    base_spec_version_c() {
      return this.quote.base_spec_version
    },
    constructionTotalCost() {
      let sum = 0
      this.quote.construction_spec_quote.forEach(e => {
        sum += parseFloat(e.total_cost)
      })

      this.quote.options
        .filter(
          n => n.is_base_spec == true && n.budget_group == 'Construction',
        )
        .forEach(e => {
          sum += parseFloat(e.total_cost)
        })

      this.quote.site_conditions
        .filter(
          n => n.is_base_spec == true && n.budget_group == 'Construction',
        )
        .forEach(e => {
          sum += parseFloat(e.total_cost)
        })

      return Number(sum)
        .toFixed(2)
    },
    baseSpecSelectedAll() {
      if (this.displayColumnsCheck.length <= 7) {
        this.allSelected = false
      } else {
        this.allSelected = true
      }
    },
    baseSpecSelectedAllFfe() {
      if (this.displayColumnsCheckFfe.length <= 7) {
        this.allSelectedFfe = false
      } else {
        this.allSelectedFfe = true
      }
    },
    baseSpecSelectedAllAvit() {
      if (this.displayColumnsCheckAvit.length <= 7) {
        this.allSelectedAvit = false
      } else {
        this.allSelectedAvit = true
      }
    },
    baseSpecSelectedAllAllow() {
      if (this.displayColumnsCheckAllow.length <= 7) {
        this.allSelectedAllow = false
      } else {
        this.allSelectedAllow = true
      }
    },
    baseSpecSelectedAllFfeAllow() {
      if (this.displayColumnsCheckFfeAllow.length <= 7) {
        this.allSelectedFfeAllow = false
      } else {
        this.allSelectedFfeAllow = true
      }
    },
    baseSpecSelectedAllAvitAllow() {
      if (this.displayColumnsCheckAvitAllow.length <= 7) {
        this.allSelectedAvitAllow = false
      } else {
        this.allSelectedAvitAllow = true
      }
    },
    baseSpecSelectedAllSc() {
      if (this.displayColumnsCheckAllSc.length <= 7) {
        this.allSelectedSc = false
      } else {
        this.allSelectedSc = true
      }
    },
    baseSpecSelectedAllScAllow() {
      if (this.displayColumnsCheck.length <= 7) {
        this.allSelectedScAllow = false
      } else {
        this.allSelectedScAllow = true
      }
    },
    cm_fee_additions_calc(){
      if(this.quote.base_spec_version>=4.2 && this.quote.cm_fee_enabled){
        let total_cstrn_items = this.cstrOptions.reduce((total, o) => o.total_price * 1 + total, 0)
            / this.quote.sqft;
        return Number(Number(total_cstrn_items) * (this.quote.cm_fee_percentage / 100))
      }else{
        return 0
      }
    },
    cm_fee_allowances_calc(){
      if(this.quote.base_spec_version>=4.2 && this.quote.cm_fee_enabled){
        let total_cstrn_items = this.cstrAllows.reduce((total, o) => o.total_price * 1 + total, 0)
            / this.quote.sqft;
        return Number(Number(total_cstrn_items) * (this.quote.cm_fee_percentage / 100))
      }else{
        return 0
      }
    }
  },
  beforeMount() {
    this.getQuotes()
    this.getAdditionSets()
  },

  methods: {
    downloadCadFile() {
      this.$http({
        url: `/quote/${this.quoteId}/cad-data`,
        method: 'GET',
        responseType: 'blob',
      })
        .then(res => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', this.quote.csv_filename)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
    },
    scrollToTop() {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    },
    getQuotes() {
      this.$http
        .get(`/quote/${this.quoteId}`)
        .then(response => {
          this.quote = response.data
          this.comments = this.quote.comments
          this.options = this.quote.options
          this.siteConditions = this.quote.site_conditions
          this.constructionSpecs = this.quote.construction_spec_quote
          this.ffeSpecs = this.quote.ffe_spec_quote
          this.avitSpecs = this.quote.avit_spec_quote
          this.show = false
          this.isBusy = false
          this.updateBreadcrumb()
          this.hasIncludedInTurnKey()
          this.quoteConstructionItems = this.quote.construction_spec_quote.filter(
            x => x.name !== 'Construction Management',
          )
          this.originalConstructionBuffer = this.quote.construction_buffer
          this.originalDesignBuffer = this.quote.design_ame_buffer
          this.originalConstructionPmBuffer = this.quote.construction_pm_buffer
          this.originalLanguage = this.quote.language
          this.originalAvItToggle = this.quote.avit_toggle
          this.originalFfeToggle = this.quote.ffe_toggle
          this.originalunion_labour_free = this.quote.assumptions_exclusions.union_labour_free
          this.originalraised_access_floor_clear = this.quote.assumptions_exclusions.raised_access_floor_clear
          this.originalffe_clear = this.quote.assumptions_exclusions.ffe_clear
          this.originalmeets_building_code = this.quote.assumptions_exclusions.meets_building_code
          this.originalbuilding_equipment_good_condition = this.quote.assumptions_exclusions.building_equipment_good_condition
          this.originalbuilt_demising_interior_partitions = this.quote.assumptions_exclusions.built_demising_interior_partitions
          this.originalhazardous_material_clear = this.quote.assumptions_exclusions.hazardous_material_clear
          this.originaldemolition = this.quote.assumptions_exclusions.demolition
          this.originalaccess_included = this.quote.assumptions_exclusions.access_included
          this.originalbas_systems_included = this.quote.assumptions_exclusions.bas_systems_included
          this.originalelectrical_panel_transformer_included = this.quote.assumptions_exclusions.electrical_panel_transformer_included
          this.originaladitional_demising_walls_included = this.quote.assumptions_exclusions.aditional_demising_walls_included
          this.originalperimeter_bulkhead_included = this.quote.assumptions_exclusions.perimeter_bulkhead_included
          this.originalblinds_included = this.quote.assumptions_exclusions.blinds_included
          this.originalsuite_entry_doors_included = this.quote.assumptions_exclusions.suite_entry_doors_included
          this.originalwashroom_work_included = this.quote.assumptions_exclusions.washroom_work_included
          this.originalelevator_lobby_work_included = this.quote.assumptions_exclusions.elevator_lobby_work_included
          this.originalabatement_hazardous_material_included = this.quote.assumptions_exclusions.abatement_hazardous_material_included
          this.originallandlord_required_reviews_included = this.quote.assumptions_exclusions.landlord_required_reviews_included
          this.permit_fees = this.quote.permit_fees_cost_per_sqft
          if (this.quote.tfr.tfr) {
            document.title = `[${this.quote.tfr.tfr}] Edit Quote`
          }
          // set budget group filters
          // * set filter based on version
          if (this.quote.base_spec_version < 3.5) {
            this.cstrOptions = this.options.filter(
              x => x.budget_group == 'Construction',
            )
          } else {
            this.cstrOptions = this.options.filter(
              x => x.budget_group == 'Construction' && !x.is_adjustment,
            )
          }
          this.avitOptions = this.options.filter(
            x => x.budget_group == 'AV/IT',
          )
          this.ffeOptions = this.options.filter(
            x => x.budget_group == 'FF&E',
          )
          this.scOptions = this.options.filter(
            x => x.budget_group == 'Soft Cost',
          )
          // init allowances variable
          this.allowances = this.quote.allowances
          // set Cash allowances filters
          this.cstrAllows = this.quote.allowances.filter(
            x => x.budget_group == 'Construction',
          )
          this.avitAllows = this.quote.allowances.filter(
            x => x.budget_group == 'AV/IT',
          )
          this.ffeAllows = this.quote.allowances.filter(
            x => x.budget_group == 'FF&E',
          )
          this.scAllows = this.quote.allowances.filter(
            x => x.budget_group == 'Soft Cost',
          )
          // set temp ffe specification swaps
          this.quote.ffe_spec_quote.map(ffe => {
            if (ffe.specification_swap != null) {
              this.swap_specifications.push(ffe.specification_swap)
            }
          })
          //  set initial additions price sqft
          this.additions_total_price_sqft = Number(this.quote.base_spec_version) >= 3.5
            ? this.options.filter(o => o.is_base_spec != true && o.is_adjustment != true)
              .reduce((total, o) => o.total_price * 1 + total, 0) / this.quote.sqft
            : this.options.reduce((total, o) => o.total_price * 1 + total, 0)
                    / this.quote.sqft

          this.cashAllowanceSubtotal = this.quote.allowances.reduce((total, allow) => total * 1 + allow.total_price * 1, 0)
        })
        .catch(error => {
          // this.showToast('danger', error.response.data.message ?? '')
        })
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].text = `${this.quote.opportunity.address}, ${this.quote.opportunity.geography}`
      this.$route.meta.breadcrumb[0].to = `/opportunities/${this.$route.params.opportunityid}`
      this.$route.meta.breadcrumb[0].active = false
      this.$route.meta.breadcrumb[2].text = `Quote #${this.quote.tfr.tfr}`
      this.$route.meta.breadcrumb[2].to = `/opportunities/${this.$route.params.opportunityid}/${this.$route.params.quoteid}`
      this.$route.meta.breadcrumb[2].active = false
      this.$root.$emit('rerender-breadcrumb')
    },
    toggleChanged(toggleCategory) {
      if (toggleCategory == 'ffe') {
        if (this.quote.ffe_toggle) {
          this.showToast(
            'primary',
            'Added FF&E budget to spaces.',
            'Added FF&E budget.',
          )
        } else {
          this.showToast(
            'primary',
            'Removed FF&E budget from spaces.',
            'Removed FF&E budget.',
          )
        }
      } else if (this.quote.avit_toggle) {
        this.showToast(
          'primary',
          'Added AV/IT budget to spaces.',
          'Added AV/IT budget.',
        )
      } else {
        this.showToast(
          'primary',
          'Removed AV/IT budget from spaces.',
          'Removed AV/IT budget.',
        )
      }
      this.hasIncludedInTurnKey()
    },
    // Save Specifications swap
    saveSpecificationSwap() {
      this.$http
        .post(`/quote/${this.quoteId}/specification-swap`, {
          specification_swap: this.swap_specifications,
          deleted_specification_swap: this.deleted_specification_swap,
        })
        .then(response => {
          this.showToast(
            'success',
            `Quote #${response.data.tfr_name} has been successfully saved.`,
            'Quote saved successfully',
          )
          // this.$router.push({
          //   path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
          // });
        })
        .catch(error => {
          this.showToast('danger', 'Error updating Quote', 'Notification')
        })
    },
    updateQuote() {
      this.buttonDiscard = true
      this.savingQuote = true

      this.$http
        .post(`/quote/${this.quoteId}/allowances/bulk`, {
          allowances: this.cash_allowances,
          deleted_allowances: this.deleted_cash_allowances,
        })
        .then(response => {
          this.allowances = response.data.allowances
          this.$http
            .post(`/quote/${this.quoteId}/options/bulk`, {
              additions: this.additions,
              deleted_additions: this.deleted_additions,
            })
            .then(response => {
              const data = response.data.options
              this.options = data
              this.$http
                .post(`/quote/${this.quoteId}/specification-swap`, {
                  specification_swap: this.swap_specifications,
                  deleted_specification_swap: this.deleted_specification_swap,
                })
                .then(response => {
                  this.$http
                    .put(`/quote/${this.quoteId}`, {
                      construction_buffer: this.quote.construction_buffer * 1,
                      avit_buffer: this.quote.avit_buffer,
                      ffe_buffer: this.quote.ffe_buffer,
                      design_ame_buffer: this.quote.design_ame_buffer,
                      construction_pm_buffer: this.quote.construction_pm_buffer,
                      issue_date: this.quote.issue_date,
                      avit_toggle: this.quote.avit_toggle,
                      av_toggle: this.quote.av_toggle,
                      it_toggle: this.quote.it_toggle,
                      ffe_toggle: this.quote.ffe_toggle,
                      assumptions_exclusions: this.quote.assumptions_exclusions,
                      language: this.quote.language,
                      options: data,
                      allowances: this.allowances,
                      engineering_fee_price: this.quote.engineering_fee_price,
                      permit_fees: this.quote.permit_fees,
                      tender_breakdown_hidden: this.quote.tender_breakdown_hidden,
                      contingency_fee_percentage: this.quote.contingency_fee_percentage,
                      cm_fee_enabled: this.quote.cm_fee_enabled,
                      cm_fee_percentage: this.quote.cm_fee_percentage,
                    })
                    .then(response => {
                      this.savingQuote = false
                      this.showToast(
                        'success',
                        `Quote #${response.data.tfr_name} has been successfully saved.`,
                        'Quote saved successfully',
                      )
                      this.$router.push({
                        path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
                      })
                    })
                    .catch(error => {
                      this.savingQuote = false
                      this.showToast(
                        'danger',
                        'Error updating Quote',
                        'Notification',
                      )
                    })
                })
                .catch(error => {
                  this.showToast('danger', 'Error updating Quote', 'Notification')
                  this.savingQuote = false
                })
            })
            .catch(error => {
              this.showToast('danger', 'Error updating Quote', 'Notification')
              this.savingQuote = false
            })
        })
        .catch(error => {
          this.showToast('danger', 'Error updating Quote', 'Notification')
          this.savingQuote = false
        })
    },
    printInvoice() {
      window.print()
    },
    initials(text) {
      const initial = text
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase()

      return initial
    },
    editOption(option) {
      this.optionToEdit = option
      // this.$bvModal.show('edit-options-modal')
    },
    deleteOption(item) {
      if (item.id == null) {
        this.additions = this.additions.filter(o => o.temp_id != item.temp_id)
        this.options = this.options.filter(o => o.temp_id != item.temp_id)
      } else {
        this.deleted_additions.push(item.id)
        this.options = this.options.filter(o => o.id != item.id)
      }
      // * set filter based on version
      if (this.quote.base_spec_version < 3.5) {
        this.cstrOptions = this.options.filter(
          x => x.budget_group == 'Construction',
        )
      } else {
        this.cstrOptions = this.options.filter(
          x => x.budget_group == 'Construction' && !x.is_adjustment,
        )
      }
      this.quote.options = this.options
      this.avitOptions = this.options.filter(x => x.budget_group == 'AV/IT')
      this.ffeOptions = this.options.filter(x => x.budget_group == 'FF&E')
      this.scOptions = this.options.filter(
        x => x.budget_group == 'Soft Cost',
      )
      //  set additions price sqft
      this.additions_total_price_sqft = Number(this.quote.base_spec_version) >= 3.5
        ? this.options.filter(o => o.is_base_spec != true && o.is_adjustment != true)
          .reduce((total, o) => o.total_price * 1 + total, 0) / this.quote.sqft
        : this.options.reduce((total, o) => o.total_price * 1 + total, 0)
              / this.quote.sqft
      // this.$http
      //   .delete(`/quote/${this.quoteId}/options/${id}`)
      //   .then((response) => {
      //     this.showToast("success", " Addition successfully deleted.", "Deleted Successfully");
      //     this.options = response.data.options;
      //     this.quote.options_price_per_sqft = response.data.price_per_sqft;
      //     this.quote.options_total_price = response.data.total_price;
      //     this.quote.options_construction_price_per_sqft =
      //       response.data.price_per_sqft_construction;
      //     this.quote.options_construction_cost_per_sqft =
      //       response.data.cost_per_sqft_construction;
      //     this.quote.options_avit_price_per_sqft = response.data.price_per_sqft_avit;
      //     this.quote.options_avit_cost_per_sqft = response.data.cost_per_sqft_avit;
      //     this.quote.options_ffe_price_per_sqft = response.data.price_per_sqft_ffe;
      //     this.quote.options_ffe_cost_per_sqft = response.data.cost_per_sqft_ffe;
      //     // set budget group filters
      //     this.cstrOptions = this.options.filter((x) => x.budget_group == "Construction");
      //     this.avitOptions = this.options.filter((x) => x.budget_group == "AV/IT");
      //     this.ffeOptions = this.options.filter((x) => x.budget_group == "FF&E");
      //     this.scOptions = this.options.filter((x) => x.budget_group == "Soft Cost");
      //   })
      // .catch((error) => {
      //   this.showToast("danger", "Error Deleting Option", "Notification");
      // });
      // console.log(this.deleted_additions);
    },
    deleteAllowance(item) {
      if (item.id == null) {
        this.cash_allowances = this.cash_allowances.filter(c => c.temp_id != item.temp_id)
        this.allowances = this.allowances.filter(c => c.temp_id != item.temp_id)
      } else {
        this.allowances.map(a => {
          if (a.id == item.id) {
            this.deleted_cash_allowances.push(a.id)
          }
        })
        this.allowances = this.allowances.filter(c => c.id != item.id)
        this.cash_allowances = this.cash_allowances.filter(c => c.id != item.id)
      }
      this.showToast(
        'success',
        'Cash Allowance successfully deleted.',
        'Deleted Successfully',
      )
      this.quote.allowances = this.allowances
      this.cstrAllows = this.allowances.filter(
        x => x.budget_group == 'Construction',
      )
      this.avitAllows = this.allowances.filter(
        x => x.budget_group == 'AV/IT',
      )
      this.ffeAllows = this.allowances.filter(x => x.budget_group == 'FF&E')
      this.scAllows = this.allowances.filter(
        x => x.budget_group == 'Soft Cost',
      )
      this.hasIncludedInTurnKey()
      this.cashAllowanceSubtotal = this.quote.allowances.reduce((total, allow) => total * 1 + allow.total_price * 1, 0)
      this.selected_allowance = []

      // this.$http
      //   .delete(`/quote/${this.quoteId}/allowances/${id}`)
      //   .then((response) => {
      //     this.showToast(
      //       "success",
      //       "Cash Allowance successfully deleted.",
      //       "Deleted Successfully"
      //     );
      //     let allowances = response.data.allowances;
      //     this.cstrAllows = allowances.filter(
      //       (x) => x.budget_group == "Construction"
      //     );
      //     this.avitAllows = allowances.filter((x) => x.budget_group == "AV/IT");
      //     this.ffeAllows = allowances.filter((x) => x.budget_group == "FF&E");
      //     this.scAllows = allowances.filter(
      //       (x) => x.budget_group == "Soft Cost"
      //     );
      //   })
      //   .catch((error) => {
      //     this.showToast(
      //       "danger",
      //       "Error Deleting Cash Allowance",
      //       "Notification"
      //     );
      //   });
    },
    deleteSiteConditions(id) {
      console.log(id)
    },

    childOptions(variable) {
      let idx = 0
      // * add addition o variable
      if (variable.id != null) {
        let findTempId = false
        let addIdx = 0
        this.options.forEach((value, index) => {
          if (value.id == variable.id) {
            idx = index
            findTempId = true
          }
        })
        // fix for not saving bulk items
        this.additions = this.additions.filter(a => a.id != variable.id)
        this.additions.push(variable)
        this.additions.forEach((value, index) => {
          if (
            value.temp_id == variable.temp_id
              && variable.temp_id != undefined
          ) {
            addIdx = index
            findTempId = true
          }
        })
        if (findTempId) {
          this.options[idx] = variable
          this.additions[addIdx] = variable
        } else {
          this.options.push(variable)
        }
      } else if (variable.temp_id != 0) {
        let findTempId = false
        let addIdx = 0
        this.options.forEach((value, index) => {
          if (
            value.temp_id == variable.temp_id
              && variable.temp_id != undefined
          ) {
            idx = index
            findTempId = true
          }
        })
        this.additions.forEach((value, index) => {
          if (
            value.temp_id == variable.temp_id
              && variable.temp_id != undefined
          ) {
            addIdx = index
            findTempId = true
          }
        })

        if (findTempId) {
          this.options[idx] = variable
          this.additions[addIdx] = variable
        } else {
          this.additions.push(variable)
          this.options.push(variable)
        }
      } else {
        // search and update by id
        if (variable.id != null) {
          this.options.forEach((value, index) => {
            if (value.id == variable.id) {
              idx = index
            }
          })
          this.options[idx] = variable
          this.additions = this.additions.filter(a => a.id != variable.id)
          this.additions.push(variable)
        } else {
          this.options.forEach((value, index) => {
            if (
              value.temp_id == variable.temp_id
                && value.temp_id != undefined
            ) {
              idx = index
            }
          })
          this.options[idx] = variable
          this.additions = this.additions.filter(
            a => a.temp_id != variable.temp_id,
          )
          this.additions.push(variable)
        }
      }
      // this.options = variable.options;
      // this.quote.options_price_per_sqft = variable.price_per_sqft;
      // this.quote.options_cost_per_sqft = variable.cost_per_sqft;
      // this.quote.options_construction_price_per_sqft =
      //   variable.price_per_sqft_construction;
      // this.quote.options_construction_cost_per_sqft = variable.cost_per_sqft_construction;
      // this.quote.options_ffe_price_per_sqft = variable.price_per_sqft_ffe;
      // this.quote.options_ffe_cost_per_sqft = variable.cost_per_sqft_ffe;
      // this.quote.options_avit_price_per_sqft = variable.price_per_sqft_avit;
      // this.quote.options_avit_cost_per_sqft = variable.cost_per_sqft_avit;
      // this.quote.options_total_price = variable.total_price;
      this.quote.options = this.options
      // * set filter based on version
      if (this.quote.base_spec_version < 3.5) {
        this.cstrOptions = this.options.filter(
          x => x.budget_group == 'Construction',
        )
      } else {
        this.cstrOptions = this.options.filter(
          x => x.budget_group == 'Construction' && !x.is_adjustment,
        )
      }
      this.avitOptions = this.options.filter(x => x.budget_group == 'AV/IT')
      this.ffeOptions = this.options.filter(x => x.budget_group == 'FF&E')
      this.scOptions = this.options.filter(
        x => x.budget_group == 'Soft Cost',
      )
      //  set  additions price sqft
      this.additions_total_price_sqft = this.additions_total_price_sqft = Number(this.quote.base_spec_version) >= 3.5
        ? this.options.filter(o => o.is_base_spec != true && o.is_adjustment != true)
          .reduce((total, o) => o.total_price * 1 + total, 0) / this.quote.sqft
        : this.options.reduce((total, o) => o.total_price * 1 + total, 0)
              / this.quote.sqft
      this.quote.options = []
      this.quote.options = this.options
      this.hasIncludedInTurnKey()
      // this.showToast("success", "Addition successfully added.", "Item Added");
      // console.log(this.additions);
    },
    childAllowances(variable) {
      if (variable.id == null && variable.temp_id == undefined) {
        if (
          this.cash_allowances.filter(a => a.temp_id == variable.temp_id).lenth > 0
        ) {
          this.cash_allowances = this.cash_allowances.filter(c => c.temp_id != variable.temp_id)
          this.allowances = this.allowances.filter(c => c.temp_id != variable.temp_id)
          this.cash_allowances.push(variable)
          this.allowances.push(variable)
        } else {
          variable.temp_id = Date.now()
          this.cash_allowances.push(variable)
          this.allowances.push(variable)
        }
      } else if (variable.temp_id != undefined) {
        // filter and add cash allowance to be sent to backend
        this.cash_allowances = this.cash_allowances.filter(c => c.temp_id != variable.temp_id)
        this.cash_allowances.push(variable)
        //  update allowances displayed in table
        let idx
        this.allowances.forEach((value, index) => {
          if (
            value.temp_id == variable.temp_id
              && value.temp_id != undefined
          ) {
            idx = index
          }
        })
        this.allowances[idx] = variable
      } else {
        console.log('here_why?')
      }
      this.quote.allowances = this.allowances
      this.cstrAllows = this.allowances.filter(
        x => x.budget_group == 'Construction',
      )
      this.avitAllows = this.allowances.filter(
        x => x.budget_group == 'AV/IT',
      )
      this.ffeAllows = this.allowances.filter(x => x.budget_group == 'FF&E')
      this.scAllows = this.allowances.filter(
        x => x.budget_group == 'Soft Cost',
      )
      this.cashAllowanceSubtotal = this.quote.allowances.reduce((total, allow) => total * 1 + allow.total_price * 1, 0)
      this.hasIncludedInTurnKey()
      // this.showToast(
      //   "success",
      //   "Cash Allowance successfully added.",
      //   "Item Added"
      // );
    },
    settingsChanged(settings) {
      this.quote.avit_toggle = settings.avit_toggle
      this.quote.av_toggle = settings.av_toggle
      this.quote.it_toggle = settings.it_toggle
      this.quote.ffe_toggle = settings.ffe_toggle
      this.quote.assumptions_exclusions = settings.assumptions_exclusions
      this.quote.language = settings.language
      this.quote.tender_breakdown_hidden = settings.tender_breakdown_hidden
      this.quote.cm_fee_enabled = settings.cm_fee_enabled
      this.quote.cm_fee_percentage = settings.cm_fee_percentage
      this.assumptionsExclusionsChanged = true
      this.hasIncludedInTurnKey()
    },
    commentChanged(comment) {
      this.childComment = comment
    },
    childSiteConditions(variable) {
      this.siteConditions = variable.site_conditions
      this.quote.site_conditions_price_per_sqft = variable.price_per_sqft
      this.quote.site_conditions_construction_price_per_sqft = variable.price_per_sqft_construction
      this.quote.site_conditions_construction_cost_per_sqft = variable.cost_per_sqft_construction
      this.quote.site_conditions_total_price = variable.total_price
    },
    editSiteCondition(option) {
      this.siteConditionToEdit = option
    },
    deleteSiteCondition(id) {
      this.$http
        .delete(`/quote/${this.quoteId}/site-conditions/${id}`)
        .then(response => {
          this.showToast(
            'success',
            'Site Condition Deleted Successfully',
            'Notification',
          )
          this.siteConditions = response.data.site_conditions
          this.quote.site_conditions_price_per_sqft = response.data.price_per_sqft
          this.quote.site_conditions_total_price = response.data.total_price
          this.quote.site_conditions_construction_price_per_sqft = response.data.price_per_sqft_construction
          this.quote.site_conditions_construction_cost_per_sqft = response.data.cost_per_sqft_construction
        })
        .catch(error => {
          this.showToast(
            'danger',
            'Error Deleting Site Condition',
            'Notification',
          )
        })
    },
    hasIncludedInTurnKey() {
      if (
        this.quote.ffe_toggle == false
          && Object.values(this.options)
            .some(
              val => val.budget_group == 'FF&E' && val.is_base_spec === true,
            )
      ) {
        this.showFFEAlert = true
      } else {
        this.showFFEAlert = false
      }

      if (
        this.quote.avit_toggle == false
          && Object.values(this.options)
            .some(
              val => val.budget_group == 'AV/IT' && val.is_base_spec === true,
            )
      ) {
        this.showAVITAlert = true
      } else {
        this.showAVITAlert = false
      }
    },

    onFilteredFFE(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.ffetotalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredAVIT(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.avittotalRows = filteredItems.length
      this.currentPage = 1
    },

    discardChanges() {
      console.log(this.$refs.commentComponent.newComment)
    },
    showModal() {
      this.buttonDiscard = true
      this.$bvModal
        .msgBoxConfirm(this.discardModalMessage, {
          title: 'You Have Unsaved Changes',
          size: 'sm',
          okVariant: 'outline-danger',
          okTitle: 'Discard Changes',
          cancelTitle: 'Return to Edit',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$router.push({
              path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
            })
          } else {
            this.buttonDiscard = false
            return false
          }
        })
    },
    exitEditMode() {
      window.location.href = `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`
      // this.$router.push({
      //   path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
      // });
    },
    visibleActions() {
      return this.quote.tfr.tfr_status == 'Quote Completed'
      || this.quote.opportunity.ccdc_signed == true
      || this.quote.tfr.tfr_status == 'Archived'
        ? 'd-none'
        : ''
    },

    badgeClass(status) {
      if (status == 'Awaiting Test Fit') {
        return 'awaiting-test-fit'
      } if (status == 'Reviewing Test Fit') {
        return 'reviewing-test-fit'
      } if (status == 'Archived') {
        return 'archived'
      } if (status == 'Awaiting Quote') {
        return 'awaiting-quote'
      } if (status == 'Quote in Progress') {
        return 'quote-in-progress'
      } if (status == 'Submitted for Handover' || status == 'Active') {
        return 'submitted-for-handover'
      } if (status == 'Reviewing Quote') {
        return 'reviewing-quote'
      } if (status == 'Sent to Client') {
        return 'sent-to-client'
      } if (status == 'Hardened') {
        return 'hardened'
      } if (status == 'Quote Completed') {
        return 'quote-completed'
      }
    },
    onChange(event) {
      this.reorder(event)
    },
    reorder(toOrder) {
      if (toOrder == 'option') {
        this.cstrOptions.forEach((item, index) => (item.order = index + 1))
        this.ffeOptions.forEach((item, index) => (item.order = index + 1))
        this.avitOptions.forEach((item, index) => (item.order = index + 1))
        this.scOptions.forEach((item, index) => (item.order = index + 1))
        this.options = [
          ...this.cstrOptions,
          ...this.ffeOptions,
          ...this.avitOptions,
          ...this.scOptions,
        ]
        this.additions = this.options
      } else {
        this.cstrAllows.forEach((item, index) => (item.order = index + 1))
        this.ffeAllows.forEach((item, index) => (item.order = index + 1))
        this.avitAllows.forEach((item, index) => (item.order = index + 1))
        this.scAllows.forEach((item, index) => (item.order = index + 1))
        this.allowances = [
          ...this.cstrAllows,
          ...this.ffeAllows,
          ...this.avitAllows,
          ...this.scAllows,
        ]
        this.cash_allowances = this.allowances
      }
    },

    avitTotals(option) {
      let total = 0
      total = this.quote.avit_spec_quote
        .filter(x => x.subcategory == option)
        .reduce((total, obj) => obj.supply_price * obj.qty + total, 0)
      return total
    },
    avitDI(option) {
      let total = 0
      total = this.quote.avit_spec_quote
        .filter(x => x.subcategory == option)
        .reduce((total, obj) => obj.unit_delivery_install * obj.qty + total, 0)
      return total
    },
    avitGlobalSubTotal() {
      let sum = 0
      sum += this.quote.it_toggle ? this.avitTotals('IT') : 0
      sum += this.quote.av_toggle ? this.avitTotals('AV') : 0
      sum += this.quote.it_toggle ? this.avitDI('IT') : 0
      sum += this.quote.av_toggle ? this.avitDI('AV') : 0
      return sum
    },
    // spec swapp on parent
    swappSpec(swappedSpec) {
      let itemSwapped = []
      if (swappedSpec.specType == 'ffe') {
        this.quote.ffe_spec_quote.map(ffe => {
          if (ffe.id == swappedSpec.ffe_spec_id) {
            ffe.specification_swap = swappedSpec
            itemSwapped = ffe.specification_swap
          }
        })
        // validate if spec swap exists
        if (
          this.swap_specifications.filter(
            item => item.ffe_spec_id == swappedSpec.ffe_spec_id,
          ).length > 0
        ) {
          this.swap_specifications = this.swap_specifications.filter(
            item => item.ffe_spec_id != swappedSpec.ffe_spec_id,
          )
          this.swap_specifications.push(itemSwapped)
        } else {
          this.swap_specifications.push(itemSwapped)
        }
      } else if (swappedSpec.specType == 'avit') {
        this.quote.avit_spec_quote.map(avit => {
          if (avit.id == swappedSpec.avit_spec_id) {
            avit.specification_swap = swappedSpec
            itemSwapped = avit.specification_swap
          }
        })
        // validate if spec swap exists
        if (
          this.swap_specifications.filter(
            item => item.avit_spec_id == swappedSpec.avit_spec_id,
          ).length > 0
        ) {
          this.swap_specifications = this.swap_specifications.filter(
            item => item.avit_spec_id != swappedSpec.avit_spec_id,
          )
          this.swap_specifications.push(itemSwapped)
        } else {
          this.swap_specifications.push(itemSwapped)
        }
      }
    },
    resetSpec(swappedSpec) {
      if (swappedSpec.specType == 'ffe') {
        this.quote.ffe_spec_quote.map(ffe => {
          if (ffe.id == swappedSpec.ffe_spec_id) {
            ffe.specification_swap = null
          }
        })
        this.swap_specifications = this.swap_specifications.filter(
          item => item.ffe_spec_id != swappedSpec.ffe_spec_id,
        )
        if (swappedSpec.id != null) {
          this.deleted_specification_swap.push(swappedSpec.id)
        }
      } else if (swappedSpec.specType == 'avit') {
        this.quote.avit_spec_quote.map(avit => {
          if (avit.id == swappedSpec.ffe_spec_id) {
            avit.specification_swap = null
          }
        })
        this.swap_specifications = this.swap_specifications.filter(
          item => item.avit_spec_id != swappedSpec.avit_spec_id,
        )
        if (swappedSpec.id != null) {
          this.deleted_specification_swap.push(swappedSpec.id)
        }
      }
    },
    resetCstrnSpec(item) {
      if (item.id == null) {
        this.options = this.options.filter(o => o.temp_id != item.temp_id)
        this.additions = this.additions.filter(
          o => o.temp_id != item.temp_id,
        )
      } else {
        this.options = this.options.filter(o => o.id != item.id)
        this.additions = this.additions.filter(o => o.id != item.id)
        this.deleted_additions.push(item.id)
      }
      this.quote.options = this.options
    },
    openUpdateAllowanceModal(item) {
      if (item.temp_id != undefined) {
        this.selected_allowance = item
      } else {
        item.temp_id = Date.now()
        this.selected_allowance = item
      }
      this.$bvModal.show('edit-allow')
    },
    searchUniformatCodeName(subgroup, code) {
      let codeName = ''
      this.uniformat_codes[subgroup].map(u => {
        if (u.code == code) {
          codeName = u.code_name
        }
      })
      return codeName
    },
    // *set cstrn margin globaly
    setContingencyFee(contingency_fee_percentage) {
      this.quote.contingency_fee_percentage = contingency_fee_percentage
    },
    //   * display columns dynamically logic
    showColumnsFiltered(col,table) {
      let isSelectedFfe = false
      let isSelected = false
      let isSelectedAvit = false
      let isSelectedAllow = false
      let isSelectedFfeAllow = false
      let isSelectedAvitAllow = false
      let isSelectedSc = false
      let isSelectedScAllow = false
      switch (table) {
        case 'cstrn_add':
          if (this.displayColumnsCheck.length > 0) {
            const res = this.displayColumnsCheck.filter(c => c == col)
            if (res.length != 0) {
              isSelected = true
            }
          }
          return isSelected
        case 'ffe_add':
          if (this.displayColumnsCheckFfe.length > 0) {
            const res = this.displayColumnsCheckFfe.filter(c => c == col)
            if (res.length != 0) {
              isSelectedFfe = true
            }
          }
          return isSelectedFfe
        case 'avit_add':
          if (this.displayColumnsCheckAvit.length > 0) {
            const res = this.displayColumnsCheckAvit.filter(c => c == col)
            if (res.length != 0) {
              isSelectedAvit = true
            }
          }
          return isSelectedAvit
        case 'ffe_allow':
          if (this.displayColumnsCheckFfeAllow.length > 0) {
            const res = this.displayColumnsCheckFfeAllow.filter(c => c == col)
            if (res.length != 0) {
              isSelectedFfeAllow = true
            }
          }
          return isSelectedFfeAllow
        case 'avit_allow':
          if (this.displayColumnsCheckAvitAllow.length > 0) {
            const res = this.displayColumnsCheckAvitAllow.filter(c => c == col)
            if (res.length !== 0) {
              isSelectedAvitAllow = true
            }
          }
          return isSelectedAvitAllow
        case 'sc_allow':
          if (this.displayColumnsCheckFfeAllow.length > 0) {
            const res = this.displayColumnsCheckScAllow.filter(c => c == col)
            if (res.length != 0) {
              isSelectedScAllow = true
            }
          }
          return isSelectedScAllow
        case 'sc_add':
          if (this.displayColumnsCheckAllSc.length > 0) {
            const res = this.displayColumnsCheckAllSc.filter(c => c == col)
            if (res.length !== 0) {
              isSelectedSc = true
            }
          }
          return isSelectedSc
        default:
          if (this.displayColumnsCheckAllow.length > 0) {
            const res = this.displayColumnsCheckAllow.filter(c => c == col)
            if (res.length != 0) {
              isSelectedAllow = true
            }
          }
          return isSelectedAllow
      }

    },
    resetCols(table) {
      switch (table) {
        case 'cnstr_add':
          this.displayColumnsCheck = [
            'name',
            'internal_note',
            'uniformat_code',
            'is_base_spec',
            'total_price',
            'actions',
          ]
          return
        case 'ffe_add':
          this.displayColumnsCheckFfe = [
            'name',
            'internal_note',
            'uniformat_code',
            'is_base_spec',
            'total_price',
            'actions',
          ]
          return
        case 'avit_add':
          this.displayColumnsCheckAvit = [
            'name',
            'internal_note',
            'uniformat_code',
            'is_base_spec',
            'total_price',
            'actions',
          ]
          return
        case 'cnstr_allow':
          this.displayColumnsCheckAllow = [
            'name',
            'internal_note',
            'uniformat_code',
            'is_base_spec',
            'total_price',
            'actions',
          ]
          return
        case 'sc_allow':
          this.displayColumnsCheckScAllow = [
            'name',
            'internal_note',
            'uniformat_code',
            'is_base_spec',
            'total_price',
            'actions',
          ]
          return
        case 'sc_add':
          this.displayColumnsCheckAllSc = [
            'name',
            'internal_note',
            'uniformat_code',
            'is_base_spec',
            'total_price',
            'actions',
          ]
          return
        case 'ffe_allow':
          this.displayColumnsCheckFfeAllow = [
            'name',
            'internal_note',
            'uniformat_code',
            'is_base_spec',
            'total_price',
            'actions',
          ]
          return
        default:
          this.displayColumnsCheckAvitAllow = [
            'name',
            'internal_note',
            'uniformat_code',
            'is_base_spec',
            'total_price',
            'actions',
          ]
          return

      }

    },
    toggleAllDrop(checked) {
      if (checked) {
        this.displayColumnsCheck = ['name']
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheck.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheck = ['name']
      }
    },
    toggleAllDropFfe(checked) {
      if (checked) {
        this.displayColumnsCheckFfe = ['name']
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheckFfe.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheckFfe = ['name']
      }
    },
    toggleAllDropAvit(checked) {
      if (checked) {
        this.displayColumnsCheckAvit = ['name']
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheckAvit.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheckAvit = ['name']
      }
    },
    toggleAllDropAllow(checked) {
      if (checked) {
        this.displayColumnsCheckAllow = ['name']
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheckAllow.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheckAllow = ['name']
      }
    },
    toggleAllDropFfeAllow(checked) {
      if (checked) {
        this.displayColumnsCheckFfeAllow = ['name']
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheckFfeAllow.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheckFfeAllow = ['name']
      }
    },
    toggleAllDropAvitAllow(checked) {
      if (checked) {
        this.displayColumnsCheckAvitAllow = ['name']
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheckAvitAllow.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheckAvitAllow = ['name']
      }
    },
    toggleAllDropScAllow(checked) {
      if (checked) {
        this.displayColumnsCheckScAllow = ['name']
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheckScAllow.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheckScAllow = ['name']
      }
    },
    toggleAllDropSc(checked) {
      if (checked) {
        this.displayColumnsCheckAllSc = ['name']
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheckAllSc.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheckAllSc = ['name']
      }
    },
    gotoAdditionSet() {
      let route = this.$router.resolve("/catalogue-addition/" + this.addition_set_selected + "/edit");
      window.open(route.href);
    },
    getAdditionSets() {
      this.$http
          .get('/additions/sets')
          .then(response => {
            const { data } = response
            this.addition_sets = data
            // this.addition_set_selected = this.addition_sets[0].id
          })
          .catch(error => {
            this.show = false
            console.log(error)
            console.log(error)
            this.showToast('danger', error.response.data.message)
          })
    },
    addAdditionSet(){
      // console.log('set addition action')
      this.is_adding_addition=true
      this.$http
          .get(`/additions/sets/${this.addition_set_selected}`,{
            quote_id: this.quote.id,
            addition_set_id: this.addition_set_selected,
            sqft: this.quote.sqft
          })
          .then(response => {
            const { data } = response
            console.log(data)
            if (data.addition_items.length > 0) {
              data.addition_items.forEach((a,index) => {
                let new_item = {
                  details: a.details,
                  total_price: a.total_price,
                  total_cost: a.total_cost,
                  cost_per_sqft: a.total_cost / this.quote.sqft,
                  price_per_sqft: a.total_price / this.quote.sqft,
                  budget_group: a.budget_group,
                  is_base_spec: a.is_base_spec,
                  tender_code: null,
                  uniformat: a.uniformat,
                  quantity: a.quantity,
                  unit_cost: a.unit_cost,
                  unit_price: a.unit_price,
                  is_adjustment: false,
                  internal_note: 'Addition created from Addition Set',
                  construction_work_id: a.construction_work_id,
                  name: a.name,
                  created: true,
                  temp_id: Date.now()+index,
                  quote_id: this.quote.id,
                  id: null,
                };
                // console.log('new item sent:', new_item);
                this.childOptions(new_item);
              });
            }
            this.closeAdditionSetModal()
            this.showToast("success", "Review and click save to apply changes.", "Addition set applied");
            this.is_adding_addition = false
          })
          .catch(error => {
            this.show = false
            console.log(error)
            this.is_adding_addition=false
            this.showToast('danger', error.response.data.message)
          })

    },
    closeAdditionSetModal(){
      this.additionSetModal=false
      this.addition_set_selected=null
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.buttonDiscard && (this.quoteHasChanged || this.hasComment)) {
      this.$bvModal
        .msgBoxConfirm(this.discardModalMessage, {
          title: 'You Have Unsaved Changes',
          size: 'sm',
          okVariant: 'outline-danger',
          okTitle: 'Discard Changes',
          cancelTitle: 'Return to Edit',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  .breadcrumbs-top {
    display: none;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
  [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
    background-color: rgba(126, 194, 66, 0.12) !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  th {
    text-align: left !important;
  }

  #lottie {
    display: none;
  }

  .caption-table {
    /* Heading/H4 */

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    padding-left: 40px;
    color: #5e5873;
  }

  .row-label {
    /* Body/Paragraph Semi Bold */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 150% */

    align-items: center;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .border-group {
    border-bottom: 2px solid #d8d6de;
  }

  .border-child {
    border-bottom: 2px solid white;
  }

  .child-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .border-footer {
    border-top: 2px solid rgba(50, 61, 76, 1) !important;
  }

  .invoice-numbercs {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
  }

  .header-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .header-label-grey {
    /* Table/Table Header */

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Typography/Muted & Placeholder */

    color: #b9b9c3;
  }

  .header-subtotal {
    font-style: normal;
    font-weight: 600;
    font-size: 11.9px;
    line-height: 12px;
    /* identical to box height, or 100% */

    text-align: right;
    letter-spacing: 0.14px;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .project-sqft {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* Typography/Heading & Display */

    color: #5e5873;
  }

  .project-sqft-total {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: end;

    /* Theme Colour / Primary */

    color: #7ec242;
  }

  .width-100p {
    width: 100%;
  }
}

th {
  text-align: left !important;
}

.comment-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.caption-table {
  /* Heading/H4 */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  padding-left: 40px;
  color: #5e5873;
}

.row-label {
  /* Body/Paragraph Semi Bold */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height, or 150% */

  align-items: center;

  /* Typography/Body */

  color: #6e6b7b;
}

.border-group {
  border-bottom: 2px solid #d8d6de;
}

.border-child {
  border-bottom: 2px solid white;
}

.child-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.border-footer {
  border-top: 2px solid rgba(59, 171, 255, 1);
}

.invoice-numbercs {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.header-label-grey {
  /* Table/Table Header */

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Typography/Muted & Placeholder */

  color: #b9b9c3;
}

.header-subtotal {
  font-style: normal;
  font-weight: 600;
  font-size: 11.9px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: right;
  letter-spacing: 0.14px;

  /* Typography/Body */

  color: #6e6b7b;
}

.project-sqft {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Typography/Heading & Display */

  color: #5e5873;
}

.project-sqft-total {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: end;

  /* Theme Colour / Primary */

  color: #7ec242;
}

.width-100p {
  width: 100%;
}

.child-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}

.cost-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}

.cost-header {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #b9b9c3;
}

.child-description {
  font-size: 12px;
  color: #b9b9c3;
  line-height: 18px;
}

.turnkey-subtotal-primary {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #6e6b7b;
}

.turnkey-subtotal-secondary {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #b9b9c3;
}

.credit {
  color: #7ec242;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.labelpersqft {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #b9b9c3;
}

th {
  text-align: center;
}

.alert-warning {
  width: 100%;
  background: linear-gradient(
          0deg,
          rgba(255, 159, 67, 0.12),
          rgba(255, 159, 67, 0.12)
  ),
  #ffffff;
  border-radius: 4px;
  margin-bottom: 2rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.alert-warning > span {
  color: #ff9f43;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}

.archived-warning {
  width: 100%;
  background: #d4f4f7;
  border-radius: 4px;
  margin-bottom: 1rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.archived-warning > span {
  color: #03d8ed;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}

.archived-warning-archived {
  width: 100%;
  background: rgba(30, 30, 30, 0.12);
  border-radius: 4px;
  margin-bottom: 1rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.archived-warning-archived > span {
  color: #4b4b4b;
  font-weight: 400;
  font-size: 14px;
  padding-left: 15px;
}

.hidden-flex-line > td {
  font-size: 12px;
}

.remove-border > td {
  border: none !important;
}

.border-section > td {
  border-bottom: 1px solid #ebe9f1;
}

.input-group > .reset-append-border > .input-group-text {
  border-left: 1px solid #d8d6de !important;
  border-top-left-radius: 0.357rem !important;
  border-bottom-left-radius: 0.357rem !important;
}

.input-group:focus-within > .reset-append-border > .input-group-text {
  border-left: 1px solid #7ec242 !important;
  border-top-left-radius: 0.357rem !important;
  border-bottom-left-radius: 0.357rem !important;
}

// @media screen and (min-width: 360px) and (max-width: 1300px) {
//     .edit-page [role=tablist] {
//       width: min-content;
//     }
// }
// @media screen and (max-width: 1440px){
//     .edit-page [role=tablist] {
//       width: 260px;
//     }
// }
// @media screen and (min-width: 1441px){
//     .edit-page [role=tablist] {
//       width: 350px;
//     }
// }

.FFE {
  background-color: rgba(32, 201, 151, 0.12);
  color: #20c997;
}

.Construction {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43;
}

.AVIT {
  background-color: rgba(13, 110, 253, 0.12);
  color: #7367f0;
}

.b-table-sticky-header {
  max-height: unset;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: rgba(50, 61, 76, 1) !important;
}

.center_header {
  text-align: center !important;
}

.sticky {
  position: sticky;
  top: 0;
  color: rgba(50, 61, 76, 1) !important;
  z-index: 10;
}
</style>
